class APIUtils {

    getBaseUrl() {
        return process.env.REACT_APP_API_URL || "https://staging-api.afbdata.com/";
    }

    getHeaders(cb) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (localStorage.hasOwnProperty('userData')) {
            let user = JSON.parse(localStorage.getItem('userData'));
            headers.append('auth_token', user.userToken);
        }
        cb(headers);
    }

    postOptions(payload) {

        const options = {};
        options.method = "POST";
        options.payload = payload;
        options.headers = this.getHeadersPost();
        return options;
    }

}

export default new APIUtils();