import React, { Component } from 'react';
import { FormContainer, NarrowContainer } from '../../components/Container';
import { Title } from '../../components/Title';
import { Divider } from '../../components/Divider';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { TextInput, TextArea } from '../../components/TextInput';
import { SelectBox } from '../../components/SelectBox';
import UserAPI from '../../api/UserAPI';
import ClientAPI from '../../api/ClientAPI';
import { ClientMenu } from '../../components/Menu';

class ClientExportPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match && this.props.match.params.id,
            setName: '',
            businessAccNo: '',
            setCurrency: '',
            groupKey: '',
            stockSource: '',
            isGroup: false,
            orderFTPAccess: false,
            excInternetOnly: false,
            excDeliveryOnly: false,
            excInStock: false,
            excOutOfStock: false,
            excLimitedStock: false,
            orderFTPFileID: "",
            orderFilePrefix: "",
            orderAddDelivery: false,
            orderReportRecipients: "",
            orderSuccessReports: false,
            orderFTPUser: "",
            orderFTPPass: "",
            orderFTPIP: "",
            isNonDPR: false,
            validatePrice: false,
            acceptClientPrice: false,
            rejectionMethod: "",
            encData: false,
            encKey: "",
            compression: false,
            outputFileName: "",
            protocolID: 0,
            ftpHost: "",
            remoteFolder: "",
            ftpUser: "",
            ftpPass: "",
            emailRecipients: "",
            errSetName: '',
            errBusinessAccNo: '',
            errSetCurrency: '',
            stockFeed: false,
            stockFeedTime: 0,
        };
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {

        UserAPI.getUserData((data) => {
            if (data) {
                this.setState({
                    userToken: data.userToken
                });
            }
        })

        if (this.state.id !== 'new') {
            this.loadClient();
        }

    }

    loadClient() {
        ClientAPI.getClient(this.state.id, (data) => {
            console.log("getClient()", data);
            this.setState({
                setName: data.setName,
                businessAccNo: data.businessAccNo,
                setCurrency: data.setCurrency,
                excInternetOnly: data.excInternetOnly,
                excDeliveryOnly: data.excDeliveryOnly,
                excInStock: data.excInStock,
                excOutOfStock: data.excOutOfStock,
                excLimitedStock: data.excLimitedStock,
                groupKey: data.groupKey,
                isGroup: data.isGroup,
                stockSource: data.stockSource,
                orderFTPAccess: data.orderFTPAccess,

                orderFTPFileID: data.orderFTPFileID,
                orderFilePrefix: data.orderFilePrefix,
                orderAddDelivery: data.orderAddDelivery,
                orderReportRecipients: data.orderReportRecipients,
                orderSuccessReports: data.orderSuccessReports,
                orderFTPUser: data.orderFTPUser,
                orderFTPPass: data.orderFTPPass,
                orderFTPIP: data.orderFTPIP,
                isNonDPR: data.isNonDPR,
                validatePrice: data.validatePrice,
                acceptClientPrice: data.acceptClientPrice,
                rejectionMethod: data.rejectionMethod,

                incProductRemovals: data.incProductRemovals,
                outputXml: data.outputXml,
                outputJson: data.outputJson,
                outputCsv: data.outputCsv,
                outputCustom: data.outputCustom,
                outputCustomXls: data.outputCustomXls,

                encData: data.encData,
                encKey: data.encKey,
                compression: data.compression,
                outputFileName: data.outputFileName,
                protocolID: data.protocolID,
                ftpHost: data.ftpHost,
                remoteFolder: data.remoteFolder,
                ftpUser: data.ftpUser,
                ftpPass: data.ftpPass,
                emailRecipients: data.emailRecipients,
                stockFeed: data.stockFeed,
                stockFeedFileName: data.stockFeedFileName,
                stockFeedProtocol: data.stockFeedProtocol,
                stockFeedHost: data.stockFeedHost,
                stockFeedFolder: data.stockFeedFolder,
                stockFeedUser: data.stockFeedUser,
                stockFeedPass: data.stockFeedPass,
                stockFeedTime: data.stockFeedTime,

            });
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
            hasErrors: false,
            errSetName: '',
            errBusinessAccNo: ''
        });
    }

    save() {

        var _ok = true;
        /*
        if (this.state.clientName === '') { 
            _ok = false;
            this.setState({
                hasErrors: true,
                errClientName: 'Please enter a client name'
            });
        }
        */

        if (_ok) {
            let payload = {
                setToken: this.state.id,
                excInternetOnly: this.state.excInternetOnly,
                excDeliveryOnly: this.state.excDeliveryOnly,
                excInStock: this.state.excInStock,
                excOutOfStock: this.state.excOutOfStock,
                excLimitedStock: this.state.excLimitedStock,
                incProductRemovals: this.state.incProductRemovals,
                outputXml: this.state.outputXml,
                outputJson: this.state.outputJson,
                outputCsv: this.state.outputCsv,
                outputCustom: this.state.outputCustom,
                outputCustomXls: this.state.outputCustomXls,
                encData: this.state.encData,
                encKey: this.state.encKey,
                compression: this.state.compression,
                outputFileName: this.state.outputFileName,
                protocolID: this.state.protocolID,
                ftpHost: this.state.ftpHost,
                remoteFolder: this.state.remoteFolder,
                ftpUser: this.state.ftpUser,
                ftpPass: this.state.ftpPass,
                emailRecipients: this.state.emailRecipients,
                stockFeed: this.state.stockFeed,
                stockFeedFileName: this.state.stockFeedFileName,
                stockFeedProtocol: this.state.stockFeedProtocol,
                stockFeedHost: this.state.stockFeedHost,
                stockFeedFolder: this.state.stockFeedFolder,
                stockFeedUser: this.state.stockFeedUser,
                stockFeedPass: this.state.stockFeedPass,
                stockFeedTime: this.state.stockFeedTime,
            }

            ClientAPI.updExport(payload, (data) => {
                this.props.history.push('/clients');
            });

        }

    }

    cancel() {
        this.props.history.push('/clients');
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <NarrowContainer>

                            <BackButton url={'/clients'} title="Back" />
                            <Title title={this.state.id === 'new' ? "New Client" : "Edit Client"} />

                            <ClientMenu id={this.state.id} />

                            <FormContainer title="Product Feed Settings"
                                description={`Which products are accessible to this client?`}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            text="Exclude Internet Only Products"
                                            name="excInternetOnly"
                                            value={this.state.excInternetOnly}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            text="Exclude Delivery Only Products"
                                            name="excDeliveryOnly"
                                            value={this.state.excDeliveryOnly}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            text="Exclude In Stock Products"
                                            name="excInStock"
                                            value={this.state.excInStock}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            text="Exclude Out of Stock Products"
                                            name="excOutOfStock"
                                            value={this.state.excOutOfStock}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            text="Exclude Limited Stock Products"
                                            name="excLimitedStock"
                                            value={this.state.excLimitedStock}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            text="Include Products for Removal"
                                            name="incProductRemovals"
                                            value={this.state.incProductRemovals}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                            </FormContainer>

                            <Divider />

                            <FormContainer title="Product Feed Format"
                                description={`Settings for processing the outbound product feed.`}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            text="Standard XML"
                                            name="outputXml"
                                            value={this.state.outputXml}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            text="Standard JSON"
                                            name="outputJson"
                                            value={this.state.outputJson}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            text="Standard CSV"
                                            name="outputCsv"
                                            value={this.state.outputCsv}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            text="Custom CSV"
                                            name="outputCustom"
                                            value={this.state.outputCustom}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <SwitchButton
                                            text="Custom XLS"
                                            name="outputCustomXls"
                                            value={this.state.outputCustomXls}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                            </FormContainer>

                            <Divider />

                            <FormContainer title="Product Feed Destination"
                                description={`Settings for processing the outbound product feed.`}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            label="Encryption"
                                            text="Enable Encryption"
                                            name="encData"
                                            value={this.state.encData}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    {this.state.encData &&
                                        <div className="col-sm-6">
                                            <TextInput
                                                name="encKey"
                                                type="text"
                                                label="Encryption Key"
                                                value={this.state.encKey}
                                                maxLength={45}
                                                asterix={true}
                                                onChange={this.onChange.bind(this)}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            label="Compression"
                                            text="Enable GZIP Compression"
                                            name="compression"
                                            value={this.state.compression}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="outputFileName"
                                            type="text"
                                            label="File Name"
                                            value={this.state.outputFileName}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <SelectBox
                                            name="protocolID"
                                            label="FTP Protocol"
                                            value={this.state.protocolID}
                                            maxLength={45}
                                            options={[{
                                                value: '1',
                                                label: 'FTP'
                                            }, {
                                                value: '2',
                                                label: 'SFTP'
                                            }, {
                                                value: '3',
                                                label: 'Local'
                                            }]}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    {this.state.protocolID !== '3' &&
                                        <div className="col-sm-6">
                                            <TextInput
                                                name="ftpHost"
                                                type="text"
                                                label="FTP Host"
                                                value={this.state.ftpHost}
                                                maxLength={45}
                                                asterix={true}
                                                onChange={this.onChange.bind(this)}
                                            />
                                        </div>
                                    }
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="remoteFolder"
                                            type="text"
                                            label="Remove Folder"
                                            value={this.state.remoteFolder}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                {this.state.protocolID !== '3' &&
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <TextInput
                                                name="ftpUser"
                                                type="text"
                                                label="FTP Username"
                                                value={this.state.ftpUser}
                                                maxLength={45}
                                                asterix={true}
                                                onChange={this.onChange.bind(this)}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <TextInput
                                                name="ftpPass"
                                                type="text"
                                                label="FTP Password"
                                                value={this.state.ftpPass}
                                                maxLength={45}
                                                asterix={true}
                                                onChange={this.onChange.bind(this)}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-sm-12">
                                        <TextArea
                                            name="emailRecipients"
                                            type="text"
                                            label="Email Recipients"
                                            value={this.state.emailRecipients}
                                            rows={3}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                            </FormContainer>

                            <Divider />

                            <FormContainer title="Stock Feed Settings"
                                description={`Settings for processing the outbound product feed.`}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            label="Stock Feed"
                                            text="Enable midday stock feed"
                                            name="stockFeed"
                                            value={this.state.stockFeed}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>

                                {this.state.stockFeed &&
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <SelectBox
                                                    name="stockFeedTime"
                                                    label="Feed Time"
                                                    value={this.state.stockFeedTime}
                                                    maxLength={45}
                                                    options={[{
                                                        value: '0',
                                                        label: 'Select...'
                                                    }, {
                                                        value: '1',
                                                        label: 'Midday (12:00)'
                                                    }, {
                                                        value: '2',
                                                        label: 'Hourly'
                                                    }, {
                                                        value: '3',
                                                        label: 'Every 4 Hours'
                                                    }]}
                                                    onChange={this.onChange.bind(this)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <TextInput
                                                    name="stockFeedFileName"
                                                    type="text"
                                                    label="File Name"
                                                    value={this.state.stockFeedFileName}
                                                    maxLength={45}
                                                    asterix={true}
                                                    onChange={this.onChange.bind(this)}
                                                    append=".csv"
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <SelectBox
                                                    name="stockFeedProtocol"
                                                    label="FTP Protocol"
                                                    value={this.state.stockFeedProtocol}
                                                    maxLength={45}
                                                    options={[{
                                                        value: '1',
                                                        label: 'FTP'
                                                    }, {
                                                        value: '2',
                                                        label: 'SFTP'
                                                    }, {
                                                        value: '3',
                                                        label: 'Local'
                                                    }]}
                                                    onChange={this.onChange.bind(this)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            {this.state.stockFeedProtocol !== "3" &&
                                                <div className="col-sm-6">
                                                    <TextInput
                                                        name="stockFeedHost"
                                                        type="text"
                                                        label="FTP Host"
                                                        value={this.state.stockFeedHost}
                                                        maxLength={45}
                                                        asterix={true}
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                </div>
                                            }
                                            <div className="col-sm-6">
                                                <TextInput
                                                    name="stockFeedFolder"
                                                    type="text"
                                                    label="Remove Folder"
                                                    value={this.state.stockFeedFolder}
                                                    maxLength={45}
                                                    asterix={true}
                                                    onChange={this.onChange.bind(this)}
                                                />
                                            </div>
                                        </div>
                                        {this.state.stockFeedProtocol !== "3" &&
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <TextInput
                                                        name="stockFeedUser"
                                                        type="text"
                                                        label="FTP Username"
                                                        value={this.state.stockFeedUser}
                                                        maxLength={45}
                                                        asterix={true}
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <TextInput
                                                        name="stockFeedPass"
                                                        type="text"
                                                        label="FTP Password"
                                                        value={this.state.stockFeedPass}
                                                        maxLength={45}
                                                        asterix={true}
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }

                            </FormContainer>

                            <Divider />

                            <div className="row">
                                <div className="col-sm-8" style={{ paddingBottom: 60 }}>
                                    <span className="invalid-error">{this.state.hasErrors ? 'Please check the errors above' : ''}</span>
                                </div>
                                <div className="col-sm-4 text-right" style={{ paddingBottom: 60 }}>
                                    <SubmitButton
                                        text={this.state.id === 'new' ? 'Create Client' : 'Update Client'}
                                        className="btn"
                                        onClick={this.save}
                                    />
                                </div>
                            </div>

                        </NarrowContainer>
                    </div>
                </div>
            </div>
        )

    }

}

export default ClientExportPage;