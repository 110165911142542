import React from 'react';

class TextInput extends React.Component {

    constructor(props) {
        super(props);
        if (this.props.url && this.props.button) {
            console.warn('Both a url and button prop were passed to the TextInput component');
        }
    }

    onFieldChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.props.onChange(name, value);
    }

    validateInput(e) {
        if (this.props.pattern) {
            const re = RegExp(this.props.pattern);
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }

        if (this.props.alhpaNumeric) {
            const re = RegExp(/[0-9A-Za-z]+/g);
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }

        if (this.props.validation === "numeric") {
            const re = RegExp(/[0-9]+/g);
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }

    }

    render() {
        return (
            <div className="ln-c-form-group">
                {this.props.label ? <label className="ln-c-label" htmlFor={this.props.name}>{this.props.label}{this.props.asterix ? <span className="asterix">*</span> : ''}</label> : ''}
                {this.props.tip ? <div className="tip">{this.props.tip}</div> : ''}
                <div className="input-group">
                    <input
                        id={this.props.name}
                        type={this.props.type ? this.props.type : "text"}
                        name={`${this.props.name}`}
                        placeholder={this.props.placeholder}
                        onChange={this.onFieldChange.bind(this)}
                        maxLength={this.props.maxLength ? this.props.maxLength : 200}
                        value={this.props.toUpper && this.props.value ? this.props.value.toUpperCase() : this.props.value}
                        className='ln-c-text-input ln-c-input-group__control'
                        onKeyPress={(e) => this.validateInput(e)}
                        autoComplete={`auto-${this.props.name}`}
                        pattern={this.props.pattern ? this.props.pattern : null}
                        disabled={this.props.disabled ? this.props.disabled : false}
                        step={this.props.step ? this.props.step : 0.01}
                        min={this.props.min ? this.props.min : 0}
                        max={this.props.max ? this.props.max : 99}
                        rows={this.props.rows ? this.props.rows : 1}
                    />
                    {this.props.append &&
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroupPrepend3">{this.props.append}</span>
                        </div>
                    }
                </div>
                <div className="input-meta">
                    {this.props.notes ? <div className="invalid-error input-meta--error"><small><em>{this.props.notes}</em></small></div> : ''}
                    {this.props.error ? <div className="invalid-error input-meta--error">{this.props.error}</div> : ''}
                </div>
            </div>
        )
    }

};

export default TextInput;