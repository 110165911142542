import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import App from "./App";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import HttpsRedirect from 'react-https-redirect';
import { unregister } from "./registerServiceWorker";
import "./scss/gate.css";
import 'react-app-polyfill/ie9';

const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_SSO_CLIENTID,
    redirectUri: process.env.REACT_APP_SSO_DOMAIN,
    authority: 'https://login.microsoftonline.com/e11fd634-26b5-47f4-8b8c-908e466e9bdf',
    navigateToLoginRequestUrl: true,
  }
};

const pca = new PublicClientApplication(configuration);

ReactDOM.render(
  <MsalProvider instance={pca}>
    <Router onUpate={() => window.scrollTo(0, 0)}>
      <HttpsRedirect>
        <App />
      </HttpsRedirect>
    </Router>
  </MsalProvider>,
  document.getElementById("root")
);
unregister();