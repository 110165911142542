import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FormContainer, NarrowContainer } from "../../components/Container";
import { Title } from "../../components/Title";
import { Divider } from "../../components/Divider";
import {
  SubmitButton,
  BackButton,
  SwitchButton,
} from "../../components/Button";
import { TextInput, CurrencyInput } from "../../components/TextInput";
import { SelectBox } from "../../components/SelectBox";
import ConsoleAPI from "../../api/ConsoleAPI";
import UserAPI from "../../api/UserAPI";

function OrderEditPage({ navigation }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const search = useLocation().search;

  const _copy = new URLSearchParams(search).get("copy");

  const [userToken, setUserToken] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientPO, setClientPO] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactLine1, setContactLine1] = useState("");
  const [contactLine2, setContactLine2] = useState("");
  const [contactTown, setContactTown] = useState("");
  const [contactPostcode, setContactPostcode] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [recipientCompany, setRecipientCompany] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [recipientLine1, setRecipientLine1] = useState("");
  const [recipientLine2, setRecipientLine2] = useState("");
  const [recipientTown, setRecipientTown] = useState("");
  const [recipientPostcode, setRecipientPostcode] = useState("");
  const [recipientTel, setRecipientTel] = useState("");
  const [contactTel, setContactTel] = useState("");
  const [accountManager, setAccountManager] = useState("");
  const [type, setType] = useState(0);
  const [qty, setQty] = useState(0);
  const [value, setValue] = useState(0);
  const [copyAddress, setCopyAddress] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [errClientName, seterrClientName] = useState("");
  const [errClientPO, seterrClientPO] = useState("");
  const [errAccountNumber, seterrAccountNumber] = useState("");
  const [errContactName, seterrContactName] = useState("");
  const [errContactEmail, seterrContactEmail] = useState("");
  const [errContactLine1, seterrContactLine1] = useState("");
  const [errContactLine2, seterrContactLine2] = useState("");
  const [errContactTown, seterrContactTown] = useState("");
  const [errContactPostcode, seterrContactPostcode] = useState("");
  const [errRecipientName, seterrRecipientName] = useState("");
  const [errRecipientEmail, seterrRecipientEmail] = useState("");
  const [errRecipientLine1, seterrRecipientLine1] = useState("");
  const [errRecipientLine2, seterrRecipientLine2] = useState("");
  const [errRecipientTown, seterrRecipientTown] = useState("");
  const [errRecipientPostcode, seterrRecipientPostcode] = useState("");
  const [errRecipientTel, seterrRecipientTel] = useState("");
  const [errContactTel, seterrContactTel] = useState("");
  const [errOrderItems, seterrOrderItems] = useState("");
  const [contactCountry, setContactCountry] = useState("GB");
  const [recipientCountry, setRecipientCountry] = useState("GB");
  const [errContactCountry, seterrContactCountry] = useState("");
  const [errRecipientCompany, seterrRecipientCompany] = useState("");
  const [errRecipientCountry, seterrRecipientCountry] = useState("");
  const [errDiscountRate, seterrDiscountRate] = useState("");
  const [errSainsburysDiscountRate, seterrSainsburysDiscountRate] =
    useState("");
  const [errHabitatDiscountRate, seterrHabitatDiscountRate] = useState("");
  const [errMultiDiscountRate, seterrMultiDiscountRate] = useState("");
  const [campaignID, setcampaignID] = useState("");
  const [saving, setsaving] = useState("");
  const [cardPayments, setCardPayments] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [displayDiscountRate, setdisplayDiscountRate] = useState(
    parseFloat(4).toFixed(2)
  );
  const [products, setproducts] = useState([]);
  const [accountManagers, setaccountManagers] = useState([]);
  const [argosDiscountRate, setargosDiscountRate] = useState(0);
  const [argosTotal, setargosTotal] = useState(0);
  const [sainsburysDiscountRate, setsainsburysDiscountRate] = useState(0);
  const [sainsburysTotal, setsainsburysTotal] = useState(0);
  const [habitatDiscountRate, setHabitatDiscountRate] = useState(0);
  const [habitatTotal, setHabitatTotal] = useState(0);
  const [multiDiscountRate, setMultiDiscountRate] = useState(0);
  const [multiTotal, setMultiTotal] = useState(0);
  const [fuelTotal, setFuelTotal] = useState(0);
  const [sainsburysTotalLessFuel, setSainsburysTotalLessFuel] = useState(0);
  const [argosCustomDiscount, setargosCustomDiscount] = useState(false);
  const [sainsburysCustomDiscount, setsainsburysCustomDiscount] =
    useState(false);
  const [habitatCustomDiscount, setHabitatCustomDiscount] = useState(false);
  const [multiCustomDiscount, setMultiCustomDiscount] = useState(false);
  const [allowCardPayments, setallowCardPayments] = useState(true);
  const [apiError, setApiError] = useState("");

  useEffect(() => {
    setHasErrors(false);
    seterrClientName("");
    seterrClientPO("");
    seterrAccountNumber("");
    seterrContactName("");
    seterrContactEmail("");
    seterrContactLine1("");
    seterrContactLine2("");
    seterrContactTown("");
    seterrContactPostcode("");
    seterrRecipientEmail("");
    seterrRecipientName("");
    seterrRecipientLine1("");
    seterrRecipientLine2("");
    seterrRecipientTown("");
    seterrRecipientPostcode("");
    seterrRecipientTel("");
    seterrContactTel("");
    seterrOrderItems("");
    seterrRecipientCountry("");
    seterrDiscountRate("");
    seterrSainsburysDiscountRate("");
    seterrHabitatDiscountRate("");
    seterrMultiDiscountRate("");
    seterrContactCountry("");
    setApiError("");
  }, [
    clientName,
    clientPO,
    accountNumber,
    contactName,
    contactEmail,
    contactLine1,
    contactLine2,
    contactTown,
    contactPostcode,
    recipientEmail,
    recipientName,
    recipientLine1,
    recipientLine2,
    recipientTown,
    recipientPostcode,
    recipientTel,
    contactTel,
    orderItems,
    recipientCountry,
    argosDiscountRate,
    sainsburysDiscountRate,
    habitatDiscountRate,
    multiDiscountRate,
    contactCountry,
    copyAddress,
  ]);

  useEffect(() => {
    UserAPI.getUserData((data) => {
      if (data) {
        setUserToken(data.userToken);
      }
    });
    loadProducts();
    loadAccountManagers();
    if (id !== "new") {
      loadOrder(id);
    } else if (_copy) {
      loadOrder(_copy);
    }
  }, []);

  const replaceWordChars = (text) => {
    var s = text;
    // smart single quotes and apostrophe
    s = s.replace(/[\u2018|\u2019|\u201A]/g, "'");
    // smart double quotes
    s = s.replace(/[\u201C|\u201D|\u201E]/g, '"');
    // ellipsis
    s = s.replace(/\u2026/g, "...");
    // dashes
    s = s.replace(/[\u2013|\u2014]/g, "-");
    // circumflex
    s = s.replace(/\u02C6/g, "^");
    // open angle bracket
    s = s.replace(/\u2039/g, "<");
    // close angle bracket
    s = s.replace(/\u203A/g, ">");
    // spaces
    s = s.replace(/[\u02DC|\u00A0]/g, " ");
    return s;
  };

  const validateOrder = (newOrderList) => {
    let payload = {
      ID: id,
      items: newOrderList,
    };

    console.log(payload);

    ConsoleAPI.validateOrder(payload, (data) => {
      console.log(data);

      setOrderItems(data.items);
      setargosDiscountRate(data.argosDiscountRate);
      setargosTotal(data.argosTotal);
      setsainsburysDiscountRate(data.sainsburysDiscountRate);
      setsainsburysTotal(data.sainsburysTotal);
      setHabitatDiscountRate(data.habitatDiscountRate);
      setHabitatTotal(data.habitatTotal);
      setMultiDiscountRate(data.multiDiscountRate);
      setMultiTotal(data.multiTotal);
      setFuelTotal(data.fuelTotal);
      setargosCustomDiscount(data.argosCustomDiscount);
      setsainsburysCustomDiscount(data.sainsburysCustomDiscount);
      setHabitatCustomDiscount(data.habitatCustomDiscount);
      setMultiCustomDiscount(data.multiCustomDiscount);
      setallowCardPayments(data.allowCardPayments);

      let _sainsburysTotalLessFuel = data.sainsburysTotal - data.fuelTotal;
      setSainsburysTotalLessFuel(_sainsburysTotalLessFuel);

      let argosCost =
        data.argosTotal -
        Number(
          Math.round(
            (data.argosTotal / 100) *
              parseFloat(data.argosDiscountRate).toFixed(2) +
              "e2"
          ) + "e-2"
        );
      let sainsburysCost =
        _sainsburysTotalLessFuel +
        data.fuelTotal -
        Number(
          Math.round(
            (_sainsburysTotalLessFuel / 100) *
              parseFloat(data.sainsburysDiscountRate).toFixed(2) +
              "e2"
          ) + "e-2"
        );
      let habitatCost =
        data.habitatTotal -
        Number(
          Math.round(
            (data.habitatTotal / 100) *
              parseFloat(data.habitatDiscountRate).toFixed(2) +
              "e2"
          ) + "e-2"
        );
      let multiCost =
        data.multiTotal -
        Number(
          Math.round(
            (data.multiTotal / 100) *
              parseFloat(data.multiDiscountRate).toFixed(2) +
              "e2"
          ) + "e-2"
        );
      if (argosCost + sainsburysCost + habitatCost + multiCost > 2500) {
        setCardPayments(false);
      }
    });
  };

  const loadProducts = () => {
    ConsoleAPI.getProducts((data) => {
      var _arr = [];
      for (var i = 0; i < data.length; i++) {
        _arr.push({
          value: data[i].ID,
          label: data[i].productName,
        });
      }
      setproducts(_arr);
    });
  };

  const loadAccountManagers = () => {
    ConsoleAPI.getAccountManagers((data) => {
      var _arr = [];
      _arr.push({
        value: 0,
        label: "Non-Managed",
      });
      for (var i = 0; i < data.length; i++) {
        _arr.push({
          value: data[i].ID,
          label: data[i].accountManager,
        });
      }
      setaccountManagers(_arr);
    });
  };

  const loadOrder = (_id) => {
    ConsoleAPI.getOneOffOrder(_id, (data) => {
      setClientName(data.clientName);
      setClientPO(data.clientPO);
      setAccountNumber(data.accountNumber);
      setContactName(data.contactName);
      setContactEmail(data.contactEmail);
      setContactLine1(data.contactLine1);
      setContactLine2(data.contactLine2);
      setContactTown(data.contactTown);
      setContactPostcode(data.contactPostcode);
      setRecipientName(data.recipientName);
      setRecipientEmail(data.recipientEmail);
      setRecipientLine1(data.recipientLine1);
      setRecipientLine2(data.recipientLine2);
      setRecipientTown(data.recipientTown);
      setRecipientPostcode(data.recipientPostcode);
      setAccountManager(data.accountManager);
      setCopyAddress(data.copyAddress);
      setContactTel(data.contactTel);
      setCardPayments(data.cardPayments);
      setContactCountry(data.contactCountry);
      setRecipientCountry(data.recipientCountry);
      setRecipientTel(data.recipientTel);
      setRecipientCompany(data.recipientCompany);
      setcampaignID(data.campaignID);
      setargosDiscountRate(
        data.discountRate
          ? parseFloat(data.discountRate).toFixed(2)
          : parseFloat(4).toFixed(2)
      );
      setsainsburysDiscountRate(
        data.sainsburysDiscountRate
          ? parseFloat(data.sainsburysDiscountRate).toFixed(2)
          : 0
      );
      setHabitatDiscountRate(
        data.habitatDiscountRate
          ? parseFloat(data.habitatDiscountRate).toFixed(2)
          : 0
      );
      setMultiDiscountRate(
        data.multiDiscountRate
          ? parseFloat(data.multiDiscountRate).toFixed(2)
          : 0
      );
      loadOrderItems(_id);
    });
  };

  const cancelOrder = () => {
    ConsoleAPI.getOneOffOrder(id, (data) => {
      setClientName(data.clientName);
      setClientPO(data.clientPO);
      setAccountNumber(data.accountNumber);
      setContactName(data.contactName);
      setContactEmail(data.contactEmail);
      setContactLine1(data.contactLine1);
      setContactLine2(data.contactLine2);
      setContactTown(data.contactTown);
      setContactPostcode(data.contactPostcode);
      setRecipientName(data.recipientName);
      setRecipientEmail(data.recipientEmail);
      setRecipientLine1(data.recipientLine1);
      setRecipientLine2(data.recipientLine2);
      setRecipientTown(data.recipientTown);
      setRecipientPostcode(data.recipientPostcode);
      setCopyAddress(data.copyAddress);
      setContactTel(data.contactTel);
      setCardPayments(data.cardPayments);
      setContactCountry(data.contactCountry);
      setRecipientCountry(data.recipientCountry);
      setRecipientTel(data.recipientTel);
      setcampaignID(data.campaignID);
      setargosDiscountRate(data.argosDiscountRate);
      setRecipientCompany(data.recipientCompany);
      setdisplayDiscountRate(data.discountRate);
      setsainsburysDiscountRate(data.sainsburysDiscountRate);
      setHabitatDiscountRate(data.habitatDiscountRate);
      setMultiDiscountRate(data.multiDiscountRate);
      setAccountManager(data.accountManager);

      let payload = {
        ID: id,
        addedBy: userToken,
        clientName: clientName,
        clientPO: clientPO,
        accountNumber: accountNumber,
        contactName: contactName,
        contactEmail: contactEmail,
        contactLine1: contactLine1,
        contactLine2: contactLine2,
        contactTown: contactTown,
        contactPostcode: contactPostcode,
        contactCountry: contactCountry,
        contactTel: contactTel,
        recipientName: recipientName,
        recipientCompany: recipientCompany,
        recipientEmail: recipientEmail,
        recipientLine1: recipientLine1,
        recipientLine2: recipientLine2,
        recipientTown: recipientTown,
        recipientPostcode: recipientPostcode,
        recipientCountry: recipientCountry,
        recipientTel: recipientTel,
        copyAddress: copyAddress,
        cardPayments: cardPayments,
        items: orderItems,
        campaignID: campaignID,
        argosDiscountRate: argosDiscountRate,
        displayDiscountRate: displayDiscountRate,
        sainsburysDiscountRate: sainsburysDiscountRate,
        habitatDiscountRate: habitatDiscountRate,
        multiDiscountRate: multiDiscountRate,
        accountManager: accountManager,
        orderStatus: 4,
      };

      ConsoleAPI.updOneOffOrder(payload, (data) => {
        navigate(`/orders/${id}`);
      });
    });
  };

  const loadOrderItems = (_id) => {
    ConsoleAPI.getOneOffOrderItems(_id, (data) => {
      console.log("getOneOffOrderItems()", data);

      validateOrder(data);
      //setOrderItems(data);
      //validateOrder();
    });
  };

  /*
    onChange(name, value) {
        
            if (name === 'discountRate') {
                let _displayDiscountRate = 0;
                if (parseFloat(value) > 10) {
                    _displayDiscountRate = 10;
                } else if (value === '') {
                    _displayDiscountRate = 0;
                } else {
                    _displayDiscountRate = value;
                }
                this.setState({
                    displayDiscountRate: _displayDiscountRate
                })
            }
    }
    */

  const save = () => {
    var _ok = true;
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (clientName === "") {
      _ok = false;
      setHasErrors(true);
      seterrClientName("Please enter a client name");
    }

    /*
                if (clientPO === '') {
                    _ok = false;
                    setHasErrors(true);
                    seterrClientPO('Please enter the client PO/Reference');
                }
        */

    if (contactName === "") {
      _ok = false;
      setHasErrors(true);
      seterrContactName("Please enter the contact name");
    }
    if (contactEmail === "") {
      _ok = false;
      setHasErrors(true);
      seterrContactEmail("Please enter the contact email");
    } else if (!re.test(contactEmail)) {
      _ok = false;
      setHasErrors(true);
      seterrContactEmail("Please enter a valid email address");
    }
    if (contactLine1 === "") {
      _ok = false;
      setHasErrors(true);
      seterrContactLine1("Please enter the contact address");
    }
    if (contactTown === "") {
      _ok = false;
      setHasErrors(true);
      seterrContactTown("Please enter the contact town");
    }
    if (contactPostcode === "") {
      _ok = false;
      setHasErrors(true);
      seterrContactPostcode("Please enter the contact postcode");
    }
    if (contactCountry === "") {
      _ok = false;
      setHasErrors(true);
      seterrContactCountry("Please enter the contact country");
    }
    if (!copyAddress && recipientName === "") {
      _ok = false;
      setHasErrors(true);
      seterrRecipientName("Please enter the recipient name");
    }
    if (!copyAddress && recipientCompany === "") {
      _ok = false;
      setHasErrors(true);
      seterrRecipientCompany(
        "Please enter the recipient Organisation/Company Name"
      );
    }
    if (!copyAddress && recipientEmail === "") {
      _ok = false;
      setHasErrors(true);
      seterrRecipientEmail("Please enter the recipient email");
    } else if (!copyAddress && !re.test(recipientEmail)) {
      _ok = false;
      setHasErrors(true);
      seterrRecipientEmail("Please enter the valid email address");
    }
    if (!copyAddress && recipientLine1 === "") {
      _ok = false;
      setHasErrors(true);
      seterrRecipientLine1("Please enter the recipient address");
    }
    if (!copyAddress && recipientTown === "") {
      _ok = false;
      setHasErrors(true);
      seterrRecipientTown("Please enter the recipient town");
    }
    if (!copyAddress && recipientPostcode === "") {
      _ok = false;
      setHasErrors(true);
      seterrRecipientPostcode("Please enter the recipient postcode");
    }
    if (!copyAddress && recipientCountry === "") {
      _ok = false;
      setHasErrors(true);
      seterrRecipientCountry("Please enter the recipient country");
    }
    if (
      !copyAddress &&
      (recipientTel === "" ||
        (recipientTel && recipientTel.length !== 11) ||
        (recipientTel && recipientTel.substring(0, 2) !== "07"))
    ) {
      _ok = false;
      setHasErrors(true);
      seterrRecipientTel("Please enter the recipient telephone number");
    }
    if (orderItems.length === 0) {
      _ok = false;
      setHasErrors(true);
      seterrOrderItems("No products have been added to this order");
    }

    if (
      contactTel === "" ||
      (contactTel && contactTel.length !== 11) ||
      (contactTel && contactTel.substring(0, 2) !== "07")
    ) {
      _ok = false;
      setHasErrors(true);
      seterrContactTel("Please enter a valid contact telephone number");
    }

    let orderTotal = orderItems
      .filter((d) => d.itemType !== 99)
      .map((v) => v.value * v.qty)
      .reduce((sum, current) => parseFloat(sum) + parseFloat(current), 0);
    if (orderTotal > 10000 && parseFloat(argosDiscountRate) > 10) {
      _ok = false;
      setHasErrors(true);
      seterrDiscountRate("Discount rate cannot exceed 10%");
    }

    if (parseFloat(sainsburysDiscountRate) > 10) {
      _ok = false;
      setHasErrors(true);
      seterrSainsburysDiscountRate("Discount rate cannot exceed 10%");
    }

    if (parseFloat(habitatDiscountRate) > 10) {
      _ok = false;
      setHasErrors(true);
      seterrHabitatDiscountRate("Discount rate cannot exceed 10%");
    }

    if (parseFloat(multiDiscountRate) > 10) {
      _ok = false;
      setHasErrors(true);
      seterrMultiDiscountRate("Discount rate cannot exceed 10%");
    }

    if (_ok) {
      setsaving(true);

      let payload = {
        ID: id === "new" ? "" : id,
        addedBy: userToken,
        clientName: replaceWordChars(clientName),
        clientPO: replaceWordChars(clientPO),
        accountNumber: accountNumber,
        contactName: replaceWordChars(contactName),
        contactEmail: replaceWordChars(contactEmail),
        contactLine1: replaceWordChars(contactLine1),
        contactLine2: replaceWordChars(contactLine2),
        contactTown: replaceWordChars(contactTown),
        contactPostcode: replaceWordChars(contactPostcode),
        contactCountry: contactCountry,
        contactTel: replaceWordChars(contactTel),
        recipientName: replaceWordChars(recipientName),
        recipientCompany: replaceWordChars(recipientCompany),
        recipientEmail: replaceWordChars(recipientEmail),
        recipientLine1: replaceWordChars(recipientLine1),
        recipientLine2: replaceWordChars(recipientLine2),
        recipientTown: replaceWordChars(recipientTown),
        recipientPostcode: replaceWordChars(recipientPostcode),
        recipientCountry: recipientCountry,
        recipientTel: replaceWordChars(recipientTel),
        copyAddress: copyAddress,
        cardPayments: cardPayments,
        campaignID: campaignID,
        argosDiscountRate: argosDiscountRate === "" ? 0 : argosDiscountRate,
        sainsburysDiscountRate:
          sainsburysDiscountRate === "" ? 0 : sainsburysDiscountRate,
        habitatDiscountRate:
          habitatDiscountRate === "" ? 0 : habitatDiscountRate,
        multiDiscountRate: multiDiscountRate === "" ? 0 : multiDiscountRate,
        accountManager: accountManager,
        items: orderItems.filter((d) => d.itemType !== 99),
      };

      console.log(payload);

      ConsoleAPI.updOneOffOrder(payload, (data) => {
        setsaving(false);
        if (data) {
          if (data && data.status === "OK") {
            if (id === "new") {
              navigate("/orders");
            } else {
              navigate(`/orders/${id}`);
            }
          } else {
            setApiError(data.message);
          }
        } else {
          setApiError("failed");
        }
      });
    }
  };

  const addItem = () => {
    if (parseInt(type) === 0) {
      seterrOrderItems("Please select a product type");
    } else if (parseInt(qty) <= 0) {
      seterrOrderItems("Card Quantity must be greater than zero");
    } else if (
      parseFloat(value) <= 0 &&
      (parseInt(type) === 1 ||
        parseInt(type) === 2 ||
        parseInt(type) === 4 ||
        parseInt(type) === 5 ||
        parseInt(type) === 10 ||
        parseInt(type) === 11 ||
        parseInt(type) === 12 ||
        parseInt(type) === 13 ||
        parseInt(type) === 16 ||
        parseInt(type) === 17 ||
        parseInt(type) === 18)
    ) {
      seterrOrderItems("Unit Value must be greater than zero");
    } else if (parseInt(type) === 11 && parseFloat(value) > 500) {
      seterrOrderItems("The maximum Unit Value is £500");
    } else if (parseInt(type) === 16 && parseFloat(value) > 500) {
      seterrOrderItems("The maximum Unit Value is £500");
    } else if (
      (parseInt(type) === 17 || parseInt(type) === 18) &&
      parseFloat(value) > 750
    ) {
      seterrOrderItems("The maximum Unit Value is £750");
    } else if (parseFloat(value) > 2000) {
      seterrOrderItems("The maximum Unit Value is £2,000");
    } else {
      let _value = 0;
      if (parseInt(type) === 7) {
        _value = 5;
      } else if (parseInt(type) === 8) {
        _value = 10;
      } else if (parseInt(type) === 9) {
        _value = 20;
      } else {
        _value = parseFloat(value);
      }

      let newOrdersList = orderItems.concat([
        { ID: 0, itemType: parseInt(type), qty: qty, value: _value },
      ]);
      console.log("newOrdersList", newOrdersList);

      //setOrderItems(newOrdersList);
      setValue(0);
      setQty(0);
      setType(0);

      validateOrder(newOrdersList);
    }
  };

  const deleteRow = (_index) => {
    let newOrdersList = orderItems
      .filter((item, index) => index !== _index)
      .filter((item, index) => item.itemType !== 99);
    validateOrder(newOrdersList);
  };

  const sum = orderItems
    .filter((d) => d.itemType !== 99)
    .map((v) => v.value * v.qty)
    .reduce((sum, current) => parseFloat(sum) + parseFloat(current), 0);
  const argosDiscount = Number(
    Math.round(
      (argosTotal / 100) * parseFloat(argosDiscountRate).toFixed(2) + "e2"
    ) + "e-2"
  );
  const sainsburysDiscount = Number(
    Math.round(
      (sainsburysTotalLessFuel / 100) *
        parseFloat(sainsburysDiscountRate).toFixed(2) +
        "e2"
    ) + "e-2"
  );
  const habitatDiscount = Number(
    Math.round(
      (habitatTotal / 100) * parseFloat(habitatDiscountRate).toFixed(2) + "e2"
    ) + "e-2"
  );
  const multiDiscount = Number(
    Math.round(
      (multiTotal / 100) * parseFloat(multiDiscountRate).toFixed(2) + "e2"
    ) + "e-2"
  );
  const discountedTotal = parseFloat(
    sum - argosDiscount - sainsburysDiscount - habitatDiscount - multiDiscount
  );

  return (
    <div className="container-fluid">
      <div className="row row-eq-height">
        <div className="col-sm-12 content">
          <NarrowContainer>
            <BackButton
              url={id === "new" ? "/orders" : `/orders/${id}`}
              title="Back"
            />
            <Title title={id === "new" ? "New Order" : "Edit Order"} />

            <FormContainer
              title="Order Information"
              description={`Please add the details for this order.  The email address entered will receive an email requesting payment.`}
            >
              <div className="row">
                <div className="col-sm-6">
                  <TextInput
                    name="clientName"
                    type="text"
                    label="Client Name"
                    value={clientName}
                    maxLength={45}
                    asterix={true}
                    onChange={(name, value) => setClientName(value)}
                    error={errClientName}
                  />
                </div>
                <div className="col-sm-6">
                  <SwitchButton
                    label="Card Payments"
                    text="Allow Card payments?"
                    name="cardPayments"
                    value={!allowCardPayments ? false : cardPayments}
                    onChange={(name, value) => setCardPayments(value)}
                    disabled={allowCardPayments ? false : true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <TextInput
                    name="accountNumber"
                    type="text"
                    label="Account Number"
                    value={accountNumber}
                    maxLength={45}
                    onChange={(name, value) => setAccountNumber(value)}
                    error={errAccountNumber}
                  />
                </div>
                <div className="col-sm-6">
                  <SelectBox
                    name="accountManager"
                    label="Account Manager"
                    value={accountManager}
                    maxLength={45}
                    asterix={true}
                    options={accountManagers}
                    onChange={(name, value) => setAccountManager(value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <TextInput
                    name="contactName"
                    type="text"
                    label="Contact Name"
                    value={contactName}
                    maxLength={45}
                    asterix={true}
                    onChange={(name, value) => setContactName(value)}
                    error={errContactName}
                  />
                </div>
                <div className="col-sm-6">
                  <TextInput
                    name="clientPO"
                    type="text"
                    label="Client PO/Reference"
                    value={clientPO}
                    maxLength={25}
                    onChange={(name, value) => setClientPO(value)}
                    error={errClientPO}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <TextInput
                    name="contactEmail"
                    type="email"
                    label="Client Email"
                    value={contactEmail}
                    maxLength={45}
                    asterix={true}
                    onChange={(name, value) => setContactEmail(value)}
                    error={errContactEmail}
                  />
                </div>
                <div className="col-sm-6">
                  <TextInput
                    name="contactTel"
                    type="tel"
                    label="Contact Telephone Number"
                    value={contactTel}
                    maxLength={11}
                    validation={"numeric"}
                    asterix={true}
                    onChange={(name, value) => setContactTel(value)}
                    error={errContactTel}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <TextInput
                    name="contactLine1"
                    type="text"
                    label="Client Address"
                    placeholder="Address line 1"
                    value={contactLine1}
                    asterix={true}
                    onChange={(name, value) => setContactLine1(value)}
                    error={errContactLine1}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <TextInput
                    name="contactLine2"
                    type="text"
                    placeholder="Address line 2"
                    value={contactLine2}
                    onChange={(name, value) => setContactLine2(value)}
                    error={errContactLine2}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <TextInput
                    name="contactTown"
                    type="text"
                    placeholder="Town/City"
                    value={contactTown}
                    onChange={(name, value) => setContactTown(value)}
                    error={errContactTown}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <TextInput
                    name="contactPostcode"
                    type="text"
                    placeholder="Post Code"
                    value={contactPostcode}
                    onChange={(name, value) => setContactPostcode(value)}
                    error={errContactPostcode}
                  />
                </div>
                <div className="col-sm-6">
                  <SelectBox
                    name="contactCountry"
                    label=""
                    placeholder="Select Country"
                    value={contactCountry}
                    maxLength={45}
                    options={[
                      {
                        value: "GB",
                        label: "United Kingdom",
                      },
                    ]}
                    disabled={true}
                    onChange={(name, value) => setContactCountry(value)}
                    error={errContactCountry}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <TextInput
                    name="campaignID"
                    type="text"
                    label="Marketing Campaign Code"
                    value={campaignID}
                    maxLength={45}
                    onChange={(name, value) => setcampaignID(value)}
                  />
                </div>
              </div>
            </FormContainer>

            <Divider />

            <FormContainer
              title="Order Items"
              description={`Please add the items for this order. Note any associated delivery charges will automatically be added once the order has been saved.`}
            >
              <div className="row">
                <div className="col-sm-12">
                  <table>
                    <thead>
                      <tr>
                        <th width="40%">Item Type</th>
                        <th width="25%">Quantity</th>
                        <th width="25%">Unit Value</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderItems
                        .filter((d) => d.itemType !== 99)
                        .map((item, index) => (
                          <tr key={index}>
                            <td>{item.productName}</td>
                            <td>{item.qty}</td>
                            <td>&pound;{item.value.toFixed(2)}</td>
                            <td>
                              {item.itemType !== 99 ? (
                                <div
                                  className="delete-item"
                                  onClick={(e) => {
                                    if (
                                      window.confirm(
                                        "Are you sure you wish to delete this item?"
                                      )
                                    )
                                      deleteRow(index);
                                  }}
                                >
                                  <i className="far fa-times-circle"></i>
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))}

                      {orderItems.filter(
                        (d) =>
                          d.itemType === 1 ||
                          d.itemType === 5 ||
                          d.itemType === 7 ||
                          d.itemType === 8 ||
                          d.itemType === 9 ||
                          d.itemType === 18
                      ).length > 0 && (
                        <tr>
                          <td>Delivery Charge</td>
                          <td>1</td>
                          <td>&pound;5.95</td>
                          <td>&nbsp;</td>
                        </tr>
                      )}

                      {orderItems.length > 0 && (
                        <tr style={{ borderBottom: 0 }}>
                          <td
                            colSpan={2}
                            style={{
                              paddingTop: 10,
                              paddingBottom: 0,
                              textAlign: "right",
                            }}
                          >
                            Total:
                          </td>
                          <td style={{ paddingTop: 10, paddingBottom: 0 }}>
                            &pound;
                            {orderItems.some(
                              (d) =>
                                d.itemType === 1 ||
                                d.itemType === 5 ||
                                d.itemType === 7 ||
                                d.itemType === 8 ||
                                d.itemType === 9 ||
                                d.itemType === 18
                            )
                              ? parseFloat(sum + 5.95).toFixed(2)
                              : sum.toFixed(2)}
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                      )}
                      {argosDiscountRate > 0 && argosTotal > 0 && (
                        <tr style={{ borderBottom: 0 }}>
                          <td
                            colSpan={2}
                            style={{ paddingBottom: 0, textAlign: "right" }}
                          >
                            Argos Discount (
                            {parseFloat(argosDiscountRate).toFixed(2)}%):
                          </td>
                          <td>&pound;{argosDiscount.toFixed(2)}</td>
                          <td>&nbsp;</td>
                        </tr>
                      )}
                      {sainsburysDiscountRate > 0 && sainsburysTotal > 0 && (
                        <tr style={{ borderBottom: 0 }}>
                          <td
                            colSpan={2}
                            style={{ paddingBottom: 0, textAlign: "right" }}
                          >
                            Sainsbury's Discount (
                            {parseFloat(sainsburysDiscountRate).toFixed(2)}%):
                          </td>
                          <td>&pound;{sainsburysDiscount.toFixed(2)}</td>
                          <td>&nbsp;</td>
                        </tr>
                      )}
                      {habitatDiscountRate > 0 && habitatTotal > 0 && (
                        <tr style={{ borderBottom: 0 }}>
                          <td
                            colSpan={2}
                            style={{ paddingBottom: 0, textAlign: "right" }}
                          >
                            Habitat Discount (
                            {parseFloat(habitatDiscountRate).toFixed(2)}%):
                          </td>
                          <td>&pound;{habitatDiscount.toFixed(2)}</td>
                          <td>&nbsp;</td>
                        </tr>
                      )}
                      {multiDiscountRate > 0 && multiTotal > 0 && (
                        <tr style={{ borderBottom: 0 }}>
                          <td
                            colSpan={2}
                            style={{ paddingBottom: 0, textAlign: "right" }}
                          >
                            Multi Brand Discount (
                            {parseFloat(multiDiscountRate).toFixed(2)}%):
                          </td>
                          <td>&pound;{multiDiscount.toFixed(2)}</td>
                          <td>&nbsp;</td>
                        </tr>
                      )}
                      {orderItems.length > 0 && (
                        <tr style={{ borderBottom: 0 }}>
                          <td
                            colSpan={2}
                            style={{ paddingBottom: 10, textAlign: "right" }}
                          >
                            <strong>Total Cost:</strong>
                          </td>
                          <td style={{ paddingBottom: 10 }}>
                            <strong>
                              &pound;
                              {orderItems.some(
                                (d) =>
                                  d.itemType === 1 ||
                                  d.itemType === 5 ||
                                  d.itemType === 7 ||
                                  d.itemType === 8 ||
                                  d.itemType === 9 ||
                                  d.itemType === 18
                              )
                                ? parseFloat(
                                    parseFloat(discountedTotal) + 5.95
                                  ).toFixed(2)
                                : parseFloat(discountedTotal).toFixed(2)}
                            </strong>
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                      )}

                      {orderItems.length === 0 && (
                        <tr>
                          <td
                            colSpan={4}
                            className="text-cell"
                            style={{ paddingTop: 10, paddingBottom: 10 }}
                          >
                            No items added.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <SelectBox
                    name="type"
                    label="Product Type"
                    placeholder="Select..."
                    value={type}
                    maxLength={45}
                    asterix={true}
                    options={products}
                    onChange={(name, value) => setType(value)}
                  />
                </div>
                <div className="col-sm-3">
                  {type !== "" && type !== 0 && (
                    <TextInput
                      name="qty"
                      type="text"
                      placeholder=""
                      label="Card Quantity"
                      value={qty}
                      asterix={true}
                      pattern={"[0-9]"}
                      onChange={(name, value) => setQty(value)}
                    />
                  )}
                </div>
                <div className="col-sm-3">
                  {(type === "1" ||
                    type === "2" ||
                    type === "4" ||
                    type === "5" ||
                    type === "10" ||
                    type === "11" ||
                    type === "12" ||
                    type === "13" ||
                    type === "14" ||
                    type === "16" ||
                    type === "17" ||
                    type === "18") && (
                    <CurrencyInput
                      name="value"
                      type="text"
                      placeholder=""
                      label="Unit Value"
                      value={value}
                      asterix={true}
                      format="currency"
                      onChange={(name, value) => setValue(value)}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  {errOrderItems && (
                    <span className="invalid-error">{errOrderItems}</span>
                  )}
                </div>
                <div className="col-sm-6 text-right">
                  {type !== "" && type !== 0 && (
                    <SubmitButton
                      text="Add to Order"
                      className="btn btn-secondary"
                      onClick={addItem}
                    />
                  )}
                </div>
              </div>
            </FormContainer>

            <Divider />

            <FormContainer
              title="Discount"
              description={`Please add any associated discount for this order.`}
            >
              <div className="row">
                <div className="col-sm-6">
                  <CurrencyInput
                    name="argosDiscountRate"
                    type="text"
                    placeholder=""
                    label="Argos Discount Percentage"
                    value={argosDiscountRate}
                    asterix={true}
                    format="currency"
                    onChange={(name, value) => setargosDiscountRate(value)}
                    disabled={argosCustomDiscount ? false : true}
                    error={
                      parseFloat(argosDiscountRate) > 10
                        ? "Discount rate cannot exceed 10%"
                        : errDiscountRate
                    }
                  />
                </div>
                <div className="col-sm-6">
                  <CurrencyInput
                    name="sainsburysDiscountRate"
                    type="text"
                    placeholder=""
                    label="Sainsbury's Discount Percentage"
                    value={sainsburysDiscountRate}
                    asterix={true}
                    format="currency"
                    onChange={(name, value) => setsainsburysDiscountRate(value)}
                    disabled={sainsburysCustomDiscount ? false : true}
                    error={
                      parseFloat(sainsburysDiscountRate) > 10
                        ? "Discount rate cannot exceed 10%"
                        : errSainsburysDiscountRate
                    }
                  />
                </div>
                <div className="col-sm-6">
                  <CurrencyInput
                    name="habitatDiscountRate"
                    type="text"
                    placeholder=""
                    label="Habitat Discount Percentage"
                    value={habitatDiscountRate}
                    asterix={true}
                    format="currency"
                    onChange={(name, value) => setHabitatDiscountRate(value)}
                    disabled={habitatCustomDiscount ? false : true}
                    error={
                      parseFloat(habitatDiscountRate) > 10
                        ? "Discount rate cannot exceed 10%"
                        : errHabitatDiscountRate
                    }
                  />
                </div>
                <div className="col-sm-6">
                  <CurrencyInput
                    name="multiDiscountRate"
                    type="text"
                    placeholder=""
                    label="Multi Brand Discount Percentage"
                    value={multiDiscountRate}
                    asterix={true}
                    format="currency"
                    onChange={(name, value) => setMultiDiscountRate(value)}
                    disabled={multiCustomDiscount ? false : true}
                    error={
                      parseFloat(multiDiscountRate) > 10
                        ? "Discount rate cannot exceed 10%"
                        : errMultiDiscountRate
                    }
                  />
                </div>
              </div>
            </FormContainer>

            <Divider />

            <FormContainer
              title="Gift Card/Voucher Recipient"
              description={`Please confirm where the gift cards/vouchers should be sent to.`}
            >
              <div className="row">
                <div className="col-sm-6">
                  <SwitchButton
                    label="Recipient Details"
                    text="Copy as above?"
                    name="copyAddress"
                    value={copyAddress}
                    onChange={(name, value) => setCopyAddress(value)}
                  />
                </div>
              </div>
              {!copyAddress && (
                <div>
                  <div className="row">
                    <div className="col-sm-6">
                      <TextInput
                        name="recipientName"
                        type="text"
                        label="Recipient Name"
                        value={recipientName}
                        maxLength={45}
                        asterix={true}
                        onChange={(name, value) => setRecipientName(value)}
                        error={errRecipientName}
                      />
                    </div>
                    <div className="col-sm-6">
                      <TextInput
                        name="recipientCompany"
                        type="text"
                        label="Organisation/Company Name"
                        value={recipientCompany}
                        maxLength={45}
                        asterix={true}
                        onChange={(name, value) => setRecipientCompany(value)}
                        error={errRecipientCompany}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <TextInput
                        name="recipientEmail"
                        type="email"
                        label="Recipient Email Address"
                        value={recipientEmail}
                        maxLength={45}
                        asterix={true}
                        onChange={(name, value) => setRecipientEmail(value)}
                        error={errRecipientEmail}
                      />
                    </div>
                    <div className="col-sm-6">
                      <TextInput
                        name="recipientTel"
                        type="tel"
                        label="Recipient Telephone Number"
                        value={recipientTel}
                        maxLength={11}
                        validation={"numeric"}
                        asterix={true}
                        onChange={(name, value) => setRecipientTel(value)}
                        error={errRecipientTel}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <TextInput
                        name="recipientLine1"
                        type="text"
                        label="Recipient Address"
                        placeholder="Address line 1"
                        value={recipientLine1}
                        asterix={true}
                        onChange={(name, value) => setRecipientLine1(value)}
                        error={errRecipientLine1}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <TextInput
                        name="recipientLine2"
                        type="text"
                        placeholder="Address line 2"
                        value={recipientLine2}
                        onChange={(name, value) => setRecipientLine2(value)}
                        error={errRecipientLine2}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <TextInput
                        name="recipientTown"
                        type="text"
                        placeholder="Town/City"
                        value={recipientTown}
                        onChange={(name, value) => setRecipientTown(value)}
                        error={errRecipientTown}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <TextInput
                        name="recipientPostcode"
                        type="text"
                        placeholder="Post Code"
                        value={recipientPostcode}
                        onChange={(name, value) => setRecipientPostcode(value)}
                        error={errRecipientPostcode}
                      />
                    </div>
                    <div className="col-sm-6">
                      <SelectBox
                        name="recipientCountry"
                        label=""
                        placeholder="Select Country"
                        value={recipientCountry}
                        maxLength={45}
                        options={[
                          {
                            value: "GB",
                            label: "United Kingdom",
                          },
                        ]}
                        disabled={true}
                        onChange={(name, value) => setRecipientCountry(value)}
                        error={errRecipientCountry}
                      />
                    </div>
                  </div>
                </div>
              )}
            </FormContainer>

            <Divider />

            <div className="row">
              <div className="col-sm-4">
                {id !== "new" && (
                  <SubmitButton
                    text="Cancel Order"
                    className="btn btn-secondary"
                    onClick={(e) => {
                      if (
                        window.confirm(
                          "Are you sure you wish to cancel this order?"
                        )
                      )
                        cancelOrder();
                    }}
                  />
                )}
              </div>
              <div
                className="col-sm-4"
                style={{ paddingBottom: 60, textAlign: "center" }}
              >
                {apiError === "failed" ? (
                  <span
                    className="invalid-error"
                    style={{ textAlign: "center" }}
                  >
                    There has been a problem continuing with your order. Please
                    wait a while and try again.
                  </span>
                ) : apiError ? (
                  <span className="invalid-error">{apiError}</span>
                ) : hasErrors ? (
                  <span className="invalid-error">
                    Please check the errors above
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div
                className="col-sm-4 text-right"
                style={{ paddingBottom: 60 }}
              >
                <SubmitButton
                  text={
                    saving
                      ? "Saving..."
                      : id === "new"
                      ? "Create Order"
                      : "Update Order"
                  }
                  className="btn"
                  onClick={save}
                  disabled={saving}
                />
              </div>
            </div>
          </NarrowContainer>
        </div>
      </div>
    </div>
  );
}

export default OrderEditPage;
