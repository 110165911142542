import React, { useState, useEffect } from "react";
import ProductAPI from "../../api/ProductAPI";
import { Loader } from "../Loader";

function CategoryTree({
  parent: initialParent = 0,
  rule = 0,
  setToken = "00000000-0000-0000-0000-000000000000",
  isTradingCategory = false,
}) {
  const [parent, setParent] = useState(initialParent);
  const [tree, setTree] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const loadTree = () => {
    ProductAPI.getTree(parent, rule, setToken, isTradingCategory, (data) => {
      setTree(data);
      setIsLoaded(true);
    });
  };

  useEffect(() => {
    loadTree();
  }, [parent]); // Reload when parent changes

  const setFolder = (id) => {
    setParent(id);
  };

  const selectItem = (id) => {
    setIsLoaded(false);
    ProductAPI.updTree(id, "A", rule, setToken, () => {
      loadTree();
    });
  };

  const selectAll = () => {
    const items = tree.map((item) => item.ID).join(",");
    setIsLoaded(false);
    ProductAPI.updTreeMultiple(items, "A", rule, setToken, () => {
      loadTree();
    });
  };

  const selectNone = () => {
    const items = tree.map((item) => item.ID).join(",");
    setIsLoaded(false);
    ProductAPI.updTreeMultiple(items, "D", rule, setToken, () => {
      loadTree();
    });
  };

  const removeItem = (id) => {
    setIsLoaded(false);
    ProductAPI.updTreeMultiple(id, "D", rule, setToken, () => {
      loadTree();
    });
  };

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div>
      <table className="tree">
        <tbody>
          {tree.map((item, index) => (
            <tr key={index}>
              <td width="5%">
                {item.selected ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => removeItem(item.ID)}
                    title="Add"
                  >
                    <i className="far fa-check-circle"></i>
                  </div>
                ) : (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => selectItem(item.ID)}
                    title="Add"
                  >
                    <i className="far fa-circle"></i>
                  </div>
                )}
              </td>
              <td width="5%">
                {item.folder ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setFolder(item.ID)}
                  >
                    <i className="fa fa-folder"></i>
                  </div>
                ) : (
                  <i className="fa fa-cube"></i>
                )}
              </td>
              <td width="90%" className="text-cell">
                {item.folder ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setFolder(item.ID)}
                  >
                    {item.name}
                  </div>
                ) : (
                  item.name
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>
        <em>
          <span
            style={{ cursor: "pointer" }}
            className="small-link"
            onClick={selectAll}
          >
            Select all
          </span>
          {" / "}
          <span
            style={{ cursor: "pointer" }}
            className="small-link"
            onClick={selectNone}
          >
            Deselect all
          </span>
        </em>
      </p>
    </div>
  );
}

export default CategoryTree;
