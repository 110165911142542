import React, { useState, useEffect } from 'react';
import { MainLink } from './';
import { useLocation } from 'react-router-dom';
import UserAPI from '../../api/UserAPI';
import { useMsal } from "@azure/msal-react";

function Menu({ route, navigation }) {

    const { accounts } = useMsal();

    const [rules, setRules] = useState(false);
    const [cashSales, setCashSales] = useState(false);
    const [reports, setReports] = useState(false);
    const [users, setUsers] = useState(false);
    const [clients, setClients] = useState(false);
    const [payments, setPayments] = useState(false);

    const loadUser = () => {
        UserAPI.getUserData((data) => {
            if (data) {
                setRules(data.permRules);
                setCashSales(data.permCashSales);
                setReports(data.permReports);
                setUsers(data.permUsers);
                setClients(data.permClients);
                setPayments(data.permPayments);
            } else {
                if (accounts.length > 0) {
                    UserAPI.getSSOUser(accounts[0].username, (data) => {
                        if (data) {
                            localStorage.setItem('userData', JSON.stringify(data));
                            setRules(data.permRules);
                            setCashSales(data.permCashSales);
                            setReports(data.permReports);
                            setUsers(data.permUsers);
                            setClients(data.permClients);
                            setPayments(data.permPayments);
                        }
                    });
                }
            }
        });
    }

    useEffect(() => {
        loadUser();
    }, []);

    const location = useLocation();
    const path = location.pathname;

    return (
        <div>
            <div className="menu">
                <nav>
                    <ul>
                        <li className={path === '/' ? 'active' : ''}><MainLink title="Dashboard" icon="fa-home" url="/" /></li>
                        {clients && <li className={(path === '/clients' || path.indexOf("clients/") > -1) ? 'active' : ''}><MainLink title="Clients" icon="fa-users" url="/clients" /></li>}
                        {rules && <li className={(path === '/rules' || path.indexOf("rules/") > -1) ? 'active' : ''}><MainLink title="Price Rules" icon="fa-percentage" url="/rules" /></li>}
                        {cashSales && <li className={(path === '/orders' || path.indexOf("orders/") > -1) ? 'active' : ''}><MainLink title="Cash Sales" icon="fa-tags" url="/orders" /></li>}
                        {payments && <li className={(path === '/payments' || path.indexOf("payments/") > -1) ? 'active' : ''}><MainLink title="Payments" icon="fa-credit-card" url="/payments" /></li>}
                        {(reports && 1 === 2) && <li className={(path === '/reports' || path.indexOf("reports/") > -1) ? 'active' : ''}><MainLink title="Reports" icon="fa-chart-pie" url="/reports" /></li>}
                        {users && <li className={(path === '/users' || path.indexOf("users/") > -1) ? 'active' : ''}><MainLink title="Users" icon="fa-users" url="/users" /></li>}
                    </ul>
                </nav>
            </div>
        </div>
    )
};

export default Menu;