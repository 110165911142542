import apiRequest from '.';

class UserAPI {

    isLoggedIn(cb) {
        if (localStorage.hasOwnProperty('userData')) {
            cb(true);
        } else {
            cb(false);
        }
    }

    getUserData(cb) {
        if (localStorage.hasOwnProperty('userData')) {
            cb(JSON.parse(localStorage.getItem('userData')));
        } else {
            cb(null);
        }
    }

    auth(payload, cb) {
        apiRequest({ endpoint: `user/signin`, method: 'POST', payload }, cb);
    }

    getUsers(options = {}, cb) {
        let url = 'user?type=1';
        apiRequest({ endpoint: url }, cb);
    }

    getSSOUser(id, cb) {
        apiRequest({ endpoint: `user/sso?username=${id}` }, cb);
    }

    getUser(id, cb) {
        apiRequest({ endpoint: `user/details?id=${id}` }, cb);
    }

    addUser(payload, cb) {
        apiRequest({ endpoint: `user`, method: 'POST', payload }, cb);
    }

    updUser(payload, cb) {
        apiRequest({ endpoint: `user/update`, method: 'POST', payload }, cb);
    }

    delUser(id, cb) {
        apiRequest({ endpoint: `user/delete?id=${id}`, method: 'POST', id }, cb);
    }

    checkUserPermissions(id, type, cb) {
        this.getUser(id, (data) => {
            if (type === "cashsales" && data.permCashSales) {
                cb(true);
            } else {
                cb(false);
            }
        })
    }


}

export default new UserAPI();