import React from "react";
import { Tabs } from "../Tabs";

const ClientMenu = ({ id }) => {
  return (
    <div>
      {id !== "71D438AC-09EB-46FD-B34D-B0569470916A" ? (
        <Tabs
          tabs={[
            {
              url: `/clients/${id}`,
              label: "Client Settings",
            },
          ]}
        />
      ) : (
        <Tabs
          tabs={[
            {
              url: `/clients/${id}`,
              label: "Client Settings",
            },
            {
              url: `/clients/rules/${id}`,
              label: "Price Rules",
            },
          ]}
        />
      )}
    </div>
  );
};

export default ClientMenu;
