import apiRequest from '.';

class NotesAPI {

    getNotes(id, cb) {
        apiRequest({ endpoint: `console/notes?id=${id}` }, cb);
    }

    addNote(payload, cb) {
        apiRequest({ endpoint: `console/notes`, method: 'POST', payload }, cb);
    }

}

export default new NotesAPI();