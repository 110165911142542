import React from 'react';

class FormContainer extends React.Component {

    render() {
        return (
            <div className="form-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="container-box">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default FormContainer;