import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../containers/Home";
import PaymentsPage from "../containers/Payments";
import PaymentPage from "../containers/Payment";
import PaymentEditPage from "../containers/PaymentEdit";
import PaymentFundsPage from "../containers/PaymentFunds";
import ReportsPage from "../containers/Reports";
import OrdersPage from "../containers/Orders";
import OrderPage from "../containers/Order";
import OrderEditPage from "../containers/OrderEdit";
import OrderFundsPage from "../containers/OrderFunds";
import UsersPage from "../containers/Users";
import UserPage from "../containers/User";
import ClientsPage from "../containers/Clients";
import ClientPage from "../containers/Client";
import ClientExportPage from "../containers/ClientExport";
import ClientLogsPage from "../containers/ClientLogs";
import ClientOrdersPage from "../containers/ClientOrders";
import ClientRulesPage from "../containers/ClientRules";
import RulesPage from "../containers/Rules";
import RulePage from "../containers/Rule";
import SettingsPage from "../containers/Settings";
import { useIsAuthenticated } from "@azure/msal-react";

function AppRoutes({ navigation, loggedIn = false }) {
  const isAuthenticated = useIsAuthenticated();

  const PrivateRoute = ({ children }) => {
    if (!isAuthenticated) {
      return <Navigate to="/" replace />;
    }
    return children;
  };

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />

      {/* Payments Routes */}
      <Route
        path="/payments"
        element={
          <PrivateRoute>
            <PaymentsPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />
      <Route
        path="/payments/:id"
        element={
          <PrivateRoute>
            <PaymentPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />
      <Route
        path="/payments/edit/:id"
        element={
          <PrivateRoute>
            <PaymentEditPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />
      <Route
        path="/payments/funds/:id"
        element={
          <PrivateRoute>
            <PaymentFundsPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />

      {/* Reports Route */}
      <Route
        path="/reports"
        element={
          <PrivateRoute>
            <ReportsPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />

      {/* Orders Routes */}
      <Route
        path="/orders"
        element={
          <PrivateRoute>
            <OrdersPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />
      <Route
        path="/orders/:id"
        element={
          <PrivateRoute>
            <OrderPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />
      <Route
        path="/orders/edit/:id"
        element={
          <PrivateRoute>
            <OrderEditPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />
      <Route
        path="/orders/funds/:id"
        element={
          <PrivateRoute>
            <OrderFundsPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />

      {/* Users Routes */}
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <UsersPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />
      <Route
        path="/users/:id"
        element={
          <PrivateRoute>
            <UserPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />

      {/* Clients Routes */}
      <Route
        path="/clients"
        element={
          <PrivateRoute>
            <ClientsPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />
      <Route
        path="/clients/:id"
        element={
          <PrivateRoute>
            <ClientPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />
      <Route
        path="/clients/export/:id"
        element={
          <PrivateRoute>
            <ClientExportPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />
      <Route
        path="/clients/logs/:id"
        element={
          <PrivateRoute>
            <ClientLogsPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />
      <Route
        path="/clients/rules/:id"
        element={
          <PrivateRoute>
            <ClientRulesPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />
      <Route
        path="/clients/orders/:id"
        element={
          <PrivateRoute>
            <ClientOrdersPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />

      {/* Rules Routes */}
      <Route
        path="/rules"
        element={
          <PrivateRoute>
            <RulesPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />
      <Route
        path="/rules/:id"
        element={
          <PrivateRoute>
            <RulePage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />

      {/* Settings Route */}
      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <SettingsPage navigation={navigation} loggedIn={loggedIn} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default AppRoutes;
