import React, { Component } from 'react';
import { FormContainer, NarrowContainer } from '../../components/Container';
import { Title } from '../../components/Title';
import { Divider } from '../../components/Divider';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { TextInput, TextArea } from '../../components/TextInput';
import UserAPI from '../../api/UserAPI';
import ClientAPI from '../../api/ClientAPI';
import { ClientMenu } from '../../components/Menu';

class ClientOrdersPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match && this.props.match.params.id,
            isDev: process.env.REACT_APP_DPI_ENV === 'development' ? true : false,
            setName: '',
            businessAccNo: '',
            setCurrency: '',
            groupKey: '',
            stockSource: '',
            isGroup: false,
            orderFTPAccess: false,
            excInternetOnly: false,
            excDeliveryOnly: false,
            excInStock: false,
            excOutOfStock: false,
            excLimitedStock: false,
            orderFTPFileID: "",
            orderFilePrefix: "",
            orderAddDelivery: false,
            orderReportRecipients: "",
            orderSuccessReports: false,
            orderFTPUser: "",
            orderFTPPass: "",
            orderFTPIP: "",
            isNonDPR: false,
            validatePrice: false,
            acceptClientPrice: false,
            rejectionMethod: "",
            encData: false,
            encKey: "",
            compression: false,
            outputFileName: "",
            protocolID: 0,
            ftpHost: "",
            remoteFolder: "",
            ftpUser: "",
            ftpPass: "",
            emailRecipients: "",
            errSetName: '',
            errBusinessAccNo: '',
            errSetCurrency: '',
            retryDays: 0,
            apiAccess: false,
            ats: 0,
            onStop: false,
            errAts: '',
        };
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {

        UserAPI.getUserData((data) => {
            if (data) {
                this.setState({
                    userToken: data.userToken
                });
            }
        })

        if (this.state.id !== 'new') {
            this.loadClient();
        }

    }

    loadClient() {
        ClientAPI.getClient(this.state.id, (data) => {
            console.log("getClient()", data);
            this.setState({
                setName: data.setName,
                businessAccNo: data.businessAccNo,
                setCurrency: data.setCurrency,
                excInternetOnly: data.excInternetOnly,
                excDeliveryOnly: data.excDeliveryOnly,
                excInStock: data.excInStock,
                excOutOfStock: data.excOutOfStock,
                excLimitedStock: data.excLimitedStock,
                groupKey: data.groupKey,
                isGroup: data.isGroup,
                stockSource: data.stockSource,
                orderFTPAccess: data.orderFTPAccess,
                ats: data.ats,
                onStop: data.onStop,
                orderFTPFileID: data.orderFTPFileID,
                orderFilePrefix: data.orderFilePrefix,
                orderAddDelivery: data.orderAddDelivery,
                orderReportRecipients: data.orderReportRecipients,
                orderSuccessReports: data.orderSuccessReports,
                orderFTPUser: data.orderFTPUser,
                orderFTPPass: data.orderFTPPass,
                orderFTPIP: data.orderFTPIP,
                isNonDPR: data.isNonDPR,
                validatePrice: data.validatePrice,
                acceptClientPrice: data.acceptClientPrice,
                rejectionMethod: data.rejectionMethod,

                incProductRemovals: data.incProductRemovals,
                outputXml: data.outputXml,
                outputJson: data.outputJson,
                outputCsv: data.outputCsv,
                outputCustom: data.outputCustom,
                outputCustomXls: data.outputCustomXls,

                encData: data.encData,
                encKey: data.encKey,
                compression: data.compression,
                outputFileName: data.outputFileName,
                protocolID: data.protocolID,
                ftpHost: data.ftpHost,
                remoteFolder: data.remoteFolder,
                ftpUser: data.ftpUser,
                ftpPass: data.ftpPass,
                emailRecipients: data.emailRecipients,

                retryDays: data.retryDays,
                apiAccess: data.apiAccess,
            });
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
            hasErrors: false,
            errSetName: '',
            errBusinessAccNo: '',
            errAts: '',
        });
    }

    save() {

        var _ok = true;

        if (this.state.ats === '') {
            _ok = false;
            this.setState({
                errAts: 'Please enter a value'
            });
        }

        if (_ok) {

            let payload = {
                setToken: this.state.id,
                retryDays: this.state.retryDays,
                orderFTPAccess: this.state.orderFTPAccess,
                orderFTPFileID: this.state.orderFTPFileID,
                orderFilePrefix: this.state.orderFilePrefix,
                orderAddDelivery: this.state.orderAddDelivery,
                orderReportRecipients: this.state.orderReportRecipients,
                isNonDPR: this.state.isNonDPR,
                rejectionMethod: this.state.rejectionMethod,
                orderSuccessReports: this.state.orderSuccessReports,
                validatePrice: this.state.validatePrice,
                acceptClientPrice: this.state.acceptClientPrice,
                apiAccess: this.state.apiAccess,
                ats: this.state.ats,
                onStop: this.state.onStop,
            }

            ClientAPI.updOrder(payload, (data) => {
                this.props.history.push('/clients');
            });
        }

    }

    cancel() {
        this.props.history.push('/clients');
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <NarrowContainer>

                            <BackButton url={'/clients'} title="Back" />
                            <Title title={this.state.id === 'new' ? "New Client" : "Edit Client"} />

                            <ClientMenu id={this.state.id} />

                            <FormContainer title="Order Processing"
                                description={`Settings for processing of inbound order files and API requests.`}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="retryDays"
                                            type="number"
                                            label="Retry Days"
                                            value={this.state.retryDays}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <SwitchButton
                                            text="Add Delivery Charges"
                                            name="orderAddDelivery"
                                            value={this.state.orderAddDelivery}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <SwitchButton
                                            text="This is a non-DPR client"
                                            name="isNonDPR"
                                            value={this.state.isNonDPR}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <SwitchButton
                                            text="Validate Price"
                                            name="validatePrice"
                                            value={this.state.validatePrice}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <SwitchButton
                                            text="Accept Client Price"
                                            name="acceptClientPrice"
                                            value={this.state.acceptClientPrice}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                            </FormContainer>

                            <Divider />

                            <FormContainer title="SFTP Order Files"
                                description={`Settings for processing of inbound order files.`}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            label="SFTP Access"
                                            text="Enable SFTP Access"
                                            name="orderFTPAccess"
                                            value={this.state.orderFTPAccess}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                {this.state.orderFTPAccess &&
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <TextInput
                                                    name="orderFTPFileID"
                                                    type="text"
                                                    label="Stream ID"
                                                    value={this.state.orderFTPFileID}
                                                    maxLength={45}
                                                    asterix={true}
                                                    onChange={this.onChange.bind(this)}
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <TextInput
                                                    name="orderFilePrefix"
                                                    type="text"
                                                    label="File Prefix"
                                                    value={this.state.orderFilePrefix}
                                                    maxLength={45}
                                                    asterix={true}
                                                    onChange={this.onChange.bind(this)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <TextArea
                                                    name="orderReportRecipients"
                                                    type="text"
                                                    label="Report Recipients"
                                                    value={this.state.orderReportRecipients}
                                                    rows={3}
                                                    asterix={true}
                                                    onChange={this.onChange.bind(this)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <SwitchButton
                                                    text="Send email notification on successful order processing?"
                                                    name="orderSuccessReports"
                                                    value={this.state.orderSuccessReports}
                                                    onChange={this.onChange.bind(this)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <TextArea
                                                    name="orderFTPIP"
                                                    type="text"
                                                    label="IP Addresses"
                                                    value={this.state.orderFTPIP}
                                                    rows={3}
                                                    asterix={true}
                                                    onChange={this.onChange.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </FormContainer>

                            <Divider />

                            <FormContainer title="API Access"
                                description={`Settings for processing of API requests.`}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            label="API Access"
                                            text="Enable API Access"
                                            name="apiAccess"
                                            value={this.state.apiAccess}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                {this.state.orderAPIAccess &&
                                    <div>

                                    </div>
                                }
                            </FormContainer>



                            <FormContainer title="ATS"
                                description={`Settings for client available to spend value.`}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="ats"
                                            type="number"
                                            label="ATS Value (£)"
                                            value={this.state.ats}
                                            maxLength={45}
                                            disabled={this.state.isDev ? false : true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errAts}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            label="Account On-Stop"
                                            text="On-Stop"
                                            name="onStop"
                                            value={this.state.onStop}
                                            disabled={this.state.isDev ? false : true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                            </FormContainer>

                            <Divider />

                            <div className="row">
                                <div className="col-sm-8" style={{ paddingBottom: 60 }}>
                                    <span className="invalid-error">{this.state.hasErrors ? 'Please check the errors above' : ''}</span>
                                </div>
                                <div className="col-sm-4 text-right" style={{ paddingBottom: 60 }}>
                                    <SubmitButton
                                        text={this.state.id === 'new' ? 'Create Client' : 'Update Client'}
                                        className="btn"
                                        onClick={this.save}
                                    />
                                </div>
                            </div>

                        </NarrowContainer>
                    </div>
                </div>
            </div>
        )

    }

}

export default ClientOrdersPage;