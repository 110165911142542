import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { Container } from "../../components/Container";
import { Title } from "../../components/Title";
import { BackButton } from "../../components/Button";
import ConsoleAPI from "../../api/ConsoleAPI";
import { PaymentSidebar } from "../../components/Sidebar";

function PaymentPage({ navigation }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [clientName, setClientName] = useState("");
  const [created, setCreated] = useState("");
  const [paymentID, setPaymentID] = useState("");
  const [isPaid, setIsPaid] = useState(false);
  const [historyData, setHistory] = useState([]);

  const loadPayment = () => {
    ConsoleAPI.getPayment(id, (data) => {
      setClientName(data.clientName);
      //setContactName(data.contactName);
      //setContactEmail(data.contactEmail);
      //setAllowCard(data.allowCard);
      //setPaymentAmount(data.paymentAmount);
      //setPaymentReference(data.paymentReference);
      //setPaymentDescription(data.paymentDescription);
      //setAccountNumber(data.accountNumber);
      //setContactTel(data.contactTel);
      //setContactLine1(data.contactLine1);
      //setContactLine2(data.contactLine2);
      //setContactTown(data.contactTown);
      //setContactPostcode(data.contactPostcode);
      setCreated(data.created);
      setPaymentID(data.paymentID);
      setIsPaid(data.isPaid);
    });
  };

  useEffect(() => {
    loadPayment();
    loadHistory();
  }, []);

  const loadHistory = () => {
    ConsoleAPI.getPaymentHistory(id, (data) => {
      setHistory(data);
    });
  };

  const fundsReceived = () => {
    navigate(`/payments/funds/${id}`);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 content">
          <BackButton title="Back" url="/payments" />
          <Title
            title={`DPP${paymentID} - ${clientName}`}
            button={!isPaid ? "Edit" : ""}
            link={!isPaid ? `/payments/edit/${id}` : ""}
          />
          <div>
            {isPaid ? (
              <span
                style={{ marginTop: -20, marginRight: 20 }}
                className="grey-warning"
              >
                Paid
              </span>
            ) : (
              <span
                style={{ marginTop: -20, marginRight: 20 }}
                className="yellow-warning"
              >
                Awaiting Payment
              </span>
            )}{" "}
            {moment(created).format("dddd DD MMMM YYYY - HH:mm")}
          </div>
        </div>
      </div>
      <div className="row row-eq-height">
        <div className="col-sm-8 content">
          <Container>
            <div className="row">
              <div className="col-sm-12">
                <h4 style={{ fontWeight: "bold", color: "#333" }}>History</h4>
                <table>
                  <thead>
                    <tr>
                      <th width="20%">Date</th>
                      <th width="80%" className="text-cell">
                        Description
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {moment(item.timeStamp).format("DD MMM YY HH:mm")}
                        </td>
                        <td className="text-cell">{item.comments}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Container>
        </div>
        <div className="col-sm-4 content">
          <PaymentSidebar id={id} />

          {!isPaid && (
            <div className="row">
              <div className="col-sm-12" style={{ paddingBottom: 20 }}>
                <button
                  className="ln-c-button ln-c-button--filled"
                  onClick={fundsReceived}
                  style={{
                    textAlign: "center",
                    width: "100%",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  Funds Received
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentPage;
