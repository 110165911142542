import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Container } from "../../components/Container";
import { Title } from "../../components/Title";
import { TextInput } from "../../components/TextInput";
import { SubmitButton } from "../../components/Button";
import { Loader } from "../../components/Loader";
import ConsoleAPI from "../../api/ConsoleAPI";
import { SelectBox } from "../../components/SelectBox";
import UserAPI from "../../api/UserAPI";

function OrdersPage() {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const size = 30;
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState(0);
  const [fromDate, setFromDate] = useState(
    moment().add(-30, "day").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));

  const [permCashSalesOrders, setPermCashSalesOrders] = useState(false);
  const [permCashSalesFunds, setPermCashSalesFunds] = useState(false);
  const [permCashSalesFundsHigher, setPermCashSalesFundsHigher] =
    useState(false);
  const [extractSent, setExtractSent] = useState(false);

  const [extractResponse, setExtractResponse] = useState([]);

  /*
                extractSent: false,
                extractResponse: false,
                userToken: '',
                permCashSalesOrders: false,
                permCashSalesFunds: false,
                permCashSalesFundsHigher: false,
                fromDate: '',
                toDate: '',
                payments: []
            */

  const loadUser = () => {
    UserAPI.getUserData((data) => {
      if (data) {
        setPermCashSalesOrders(data.permCashSalesOrders);
        setPermCashSalesFunds(data.permCashSalesFunds);
        setPermCashSalesFundsHigher(data.permCashSalesFundsHigher);

        UserAPI.checkUserPermissions(data.userToken, "cashsales", (perm) => {
          if (!perm) {
            navigate("/");
          }
        });
      }
    });
  };

  const extract = () => {
    ConsoleAPI.getOneOffOrderExtract((data) => {
      setExtractSent(true);
      setExtractResponse(data);
    });
  };

  useEffect(() => {
    loadOrders(1);
    loadUser();
  }, []);

  const filterOrders = () => {
    setPage(1);
    loadOrders(1);
  };

  const loadOrders = (_page) => {
    setIsLoading(true);
    var _ok = true;
    if (_ok) {
      console.log({
        keyword,
        fromDate,
        toDate,
        status,
        _page,
        size,
      });

      ConsoleAPI.getOneOffOrders(
        keyword,
        fromDate,
        toDate,
        status,
        _page,
        size,
        (data) => {
          console.log(data);
          setCount(data.count);
          setOrders(data.items);
          setIsLoading(false);
        }
      );
    }
  };

  const exportData = () => {
    var _ok = true;
    if (_ok) {
      ConsoleAPI.exportOrders(keyword, fromDate, toDate, status, (data) => {
        console.log(data);
        window.open(data.url); //to open new page
      });
    }
  };

  const prevPage = () => {
    setPage(page - 1);
    loadOrders(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
    loadOrders(page + 1);
  };

  /*
    const onBulkPaymentToggle = (event) => {
        const target = event.target;
        //const value = target.type === 'checkbox' ? target.checked : target.value;
        const value = target.value;
        const name = target.name;

        if (payments.includes(value)) {
            this.setState({
                payments: payments.filter((item, index) => item !== value)
            });
        } else {
            this.setState({
                payments: payments.concat([value])
            });
        }

        //this.props.onChange(name, value);
    }
    */

  /*
        const bulkConfirm = () => {
            console.log(payments);
    
            for (var i = 0; i < payments.length; i++) {
    
                let payload = {
                    ID: payments[i],
                    confirmedBy: userToken,
                    paymentMethod: 0,
                    fundsReceived: moment().format("YYYY-MM-DD"),
                    transRef: ""
                }
    
                ConsoleAPI.updOneOffOrderPayment(payload, (data) => {
    
                });
    
            };
            loadOrders(1);
        }
        */

  return (
    <div className="container-fluid">
      <div className="row row-eq-height">
        <div className="col-sm-12 content">
          <Title
            title="Cash Sales Orders"
            button={permCashSalesOrders ? "Add new" : ""}
            link={permCashSalesOrders ? "/orders/edit/new" : ""}
          />
          <Container>
            <div className="row">
              <div className="col-sm-3">
                <TextInput
                  name="keyword"
                  text="Search"
                  label="Keyword"
                  value={keyword}
                  onChange={(name, value) => setKeyword(value)}
                />
              </div>
              <div className="col-sm-3">
                <TextInput
                  type="date"
                  name="fromDate"
                  placeholder="From"
                  label="Period From"
                  value={fromDate}
                  onChange={(name, value) => setFromDate(value)}
                />
              </div>
              <div className="col-sm-3">
                <TextInput
                  type="date"
                  name="toDate"
                  placeholder="To"
                  label="Period To"
                  value={toDate}
                  onChange={(name, value) => setToDate(value)}
                />
              </div>
              <div className="col-sm-3">
                <SelectBox
                  name="status"
                  label="Status"
                  value={status}
                  maxLength={45}
                  options={[
                    {
                      value: 0,
                      label: "All Statuses",
                    },
                    {
                      value: 1,
                      label: "Awaiting Payment",
                    },
                    {
                      value: 5,
                      label: "Payment Made",
                    },
                    {
                      value: 2,
                      label: "Payment Received",
                    },
                    {
                      value: 3,
                      label: "Despatched",
                    },
                    {
                      value: 4,
                      label: "Cancelled",
                    },
                  ]}
                  onChange={(name, value) => setStatus(value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-2 offset-sm-4">
                <div className="form-group">
                  <label>&nbsp;</label>
                  <div className="input-group">
                    <SubmitButton
                      text="Filter"
                      className="btn btn-full"
                      full={true}
                      onClick={filterOrders}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label>&nbsp;</label>
                  <div className="input-group">
                    <SubmitButton
                      text="Export"
                      className="btn btn-full btn-secondary"
                      full={true}
                      onClick={exportData}
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr />

            {isLoading ? (
              <Loader />
            ) : (
              <div>
                <table>
                  <thead>
                    <tr>
                      <th width="10%">Reference</th>
                      <th width="10%">Date</th>
                      <th width="20%">Customer</th>
                      <th width="10%">PO/Ref</th>
                      <th width="10%">Brand</th>
                      <th width="15%">Order Details</th>
                      <th width="10%" className="text-right">
                        Order Cost
                      </th>
                      <th width="10%">Status</th>
                      {1 === 2 && <th width="5%">&nbsp;</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {orders.length === 0 && (
                      <tr>
                        <td colSpan="8" className="text-center">
                          No orders found.
                        </td>
                      </tr>
                    )}

                    {orders.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Link to={`/orders/${item.ID}`}>
                            DPI{item.orderNumber}
                          </Link>
                        </td>
                        <td>{moment(item.orderDate).format("DD MMM YYYY")}</td>
                        <td>
                          {item.clientName.length > 30
                            ? `${item.clientName.substring(0, 20)}...`
                            : item.clientName}
                        </td>
                        <td>
                          {item.clientPO.length > 20
                            ? `${item.clientPO.substring(0, 20)}...`
                            : item.clientPO}
                        </td>
                        <td>{item.brand}</td>
                        <td>{item.summary}</td>
                        <td className="text-right">
                          &pound;
                          {item.totalCost
                            ? parseFloat(
                                item.totalCost - item.discount
                              ).toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0}
                        </td>
                        <td>
                          {item.orderStatus === 4 ? (
                            <span className="grey-warning">Cancelled</span>
                          ) : item.orderStatus === 3 ? (
                            <span className="grey-warning">Despatched</span>
                          ) : item.orderStatus === 2 ? (
                            <span className="grey-warning">
                              Payment Received
                            </span>
                          ) : item.orderStatus === 5 ? (
                            <span className="red-warning">Payment Made</span>
                          ) : (
                            <span className="yellow-warning">
                              Awaiting Payment
                            </span>
                          )}
                        </td>
                        {1 === 2 && (
                          <td style={{ position: "relative" }}>
                            {item.orderStatus <= 1 &&
                              ((permCashSalesFunds &&
                                item.totalCost - item.discount < 5000) ||
                                (permCashSalesFundsHigher &&
                                  item.totalCost - item.discount >= 5000)) && (
                                <input
                                  className="form-check-input"
                                  style={{ position: "absolute", top: "10px" }}
                                  type="checkbox"
                                  onChange={this.onBulkPaymentToggle.bind(this)}
                                  value={item.ID}
                                />
                              )}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/*payments.length > 0 &&
                                    <div className="row">
                                        <div className="col-sm-12" style={{ textAlign: 'right', paddingBottom: 60 }}>
                                            <SubmitButton
                                                text="Confirm Payments Received"
                                                className="btn btn-primary"
                                                onClick={bulkConfirm}
                                            />
                                        </div>
                                    </div>
                                            */}

                <div className="paging">
                  <p>
                    Showing page {page} of {Math.ceil(count / size)}
                  </p>
                  <button
                    className={page > 1 ? "prev" : "prev disabled"}
                    disabled={page > 1 ? false : true}
                    onClick={prevPage}
                  >
                    <i className="fas fa-angle-left"></i>
                  </button>
                  <button
                    className={count > size * page ? "next" : "next disabled"}
                    disabled={count > size * page ? false : true}
                    onClick={nextPage}
                  >
                    <i className="fas fa-angle-right"></i>
                  </button>
                </div>
              </div>
            )}
          </Container>

          {process.env.REACT_APP_DPI_ENV === "development" && (
            <div className="row">
              <div className="col-sm-12" style={{ paddingBottom: 60 }}>
                <SubmitButton
                  text="Send Extract"
                  className="btn btn-secondary"
                  onClick={extract}
                />
                {extractSent ? (
                  <span style={{ fontSize: "11px", marginLeft: 15 }}>
                    {extractResponse ? "Extract Sent!" : "No Orders to Send."}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrdersPage;
