import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Container } from "../../components/Container";
import { Title } from "../../components/Title";
import { TextInput } from "../../components/TextInput";
import { SubmitButton } from "../../components/Button";
import { Loader } from "../../components/Loader";
import ConsoleAPI from "../../api/ConsoleAPI";
import { SelectBox } from "../../components/SelectBox";

function PaymentsPage({ navigation, params }) {
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const size = 30;
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [status, setStatus] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    loadPayments(1);
  }, []);

  const filterPayments = () => {
    setPage(1);
    loadPayments(1);
  };

  const loadPayments = (_page) => {
    setIsLoading(true);
    ConsoleAPI.getPayments(
      keyword,
      fromDate,
      toDate,
      status,
      _page,
      size,
      (data) => {
        setCount(data.count);
        setPayments(data.items);
        setIsLoading(false);
      }
    );
  };

  const exportData = () => {
    var _ok = true;
    if (_ok) {
      ConsoleAPI.exportPayments(keyword, fromDate, toDate, status, (data) => {
        console.log(data);
        window.open(data.url); //to open new page
      });
    }
  };

  const prevPage = () => {
    setPage(page - 1);
    loadPayments(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
    loadPayments(page + 1);
  };

  return (
    <div className="container-fluid">
      <div className="row row-eq-height">
        <div className="col-sm-12 content">
          <Title title="Payments" button="Add new" link="/payments/edit/new" />
          <Container>
            <div className="row">
              <div className="col-sm-3">
                <TextInput
                  name="keyword"
                  text="Search"
                  label="Keyword"
                  value={keyword}
                  onChange={(name, value) => setKeyword(value)}
                />
              </div>
              <div className="col-sm-3">
                <TextInput
                  type="date"
                  name="fromDate"
                  placeholder="From"
                  label="Period From"
                  value={fromDate}
                  onChange={(name, value) => setFromDate(value)}
                />
              </div>
              <div className="col-sm-3">
                <TextInput
                  type="date"
                  name="toDate"
                  placeholder="To"
                  label="Period To"
                  value={toDate}
                  onChange={(name, value) => setToDate(value)}
                />
              </div>
              <div className="col-sm-3">
                <SelectBox
                  name="status"
                  label="Status"
                  value={status}
                  maxLength={45}
                  options={[
                    {
                      value: 0,
                      label: "All Statuses",
                    },
                    {
                      value: 1,
                      label: "Awaiting Payment",
                    },
                    {
                      value: 2,
                      label: "Payment Complete",
                    },
                  ]}
                  onChange={(name, value) => setStatus(value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-2 offset-sm-4">
                <div className="form-group">
                  <label>&nbsp;</label>
                  <div className="input-group">
                    <SubmitButton
                      text="Filter"
                      className="btn btn-full"
                      full={true}
                      onClick={filterPayments}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label>&nbsp;</label>
                  <div className="input-group">
                    <SubmitButton
                      text="Export"
                      full={true}
                      className="btn btn-full btn-secondary"
                      onClick={exportData}
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr />

            {isLoading ? (
              <Loader />
            ) : (
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Date</th>
                      <th>Customer</th>
                      <th>Reference</th>
                      <th className="text-right">Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Link to={`/payments/${item.ID}`}>
                            DPP{item.paymentID}
                          </Link>
                        </td>
                        <td>{moment(item.created).format("DD MMM YYYY")}</td>
                        <td>{item.clientName}</td>
                        <td>{item.paymentReference}</td>
                        <td className="text-right">
                          &pound;
                          {item.paymentAmount.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td>
                          {item.isPaid ? (
                            <span className="grey-warning">Paid</span>
                          ) : (
                            <span className="yellow-warning">
                              Awaiting Payment
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="paging">
                  <p>
                    Showing page {page} of {Math.ceil(count / size)}
                  </p>
                  <button
                    className={page > 1 ? "prev" : "prev disabled"}
                    disabled={page > 1 ? false : true}
                    onClick={prevPage}
                  >
                    <i className="fas fa-angle-left"></i>
                  </button>
                  <button
                    className={count > size * page ? "next" : "next disabled"}
                    disabled={count > size * page ? false : true}
                    onClick={nextPage}
                  >
                    <i className="fas fa-angle-right"></i>
                  </button>
                </div>
              </div>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
}

export default PaymentsPage;
