import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FormContainer, NarrowContainer } from "../../components/Container";
import { Title } from "../../components/Title";
import { Divider } from "../../components/Divider";
import {
  SubmitButton,
  BackButton,
  SwitchButton,
} from "../../components/Button";
import { TextInput, TextArea, CurrencyInput } from "../../components/TextInput";
import ConsoleAPI from "../../api/ConsoleAPI";

function PaymentEditPage({ navigation }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [clientName, setClientName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [allowCard, setAllowCard] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentReference, setPaymentReference] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [contactTel, setContactTel] = useState("");
  const [contactLine1, setContactLine1] = useState("");
  const [contactLine2, setContactLine2] = useState("");
  const [contactTown, setContactTown] = useState("");
  const [contactPostcode, setContactPostcode] = useState("");
  const [hasErrors, setHasErrors] = useState(false);
  const [errClientName, setErrClientName] = useState("");
  const [errContactName, setErrContactName] = useState("");
  const [errContactEmail, setErrContactEmail] = useState("");
  const [errPaymentReference, setErrPaymentReference] = useState("");
  const [errPaymentAmount, setErrPaymentAmount] = useState("");
  const [errPaymentDescription, setErrPaymentDescription] = useState(false);
  const [errContactTel, setErrContactTel] = useState("");
  const [errAccountNumber, setErrAccountNumber] = useState("");
  const [errContactLine1, setErrContactLine1] = useState("");
  const [errContactLine2, setErrContactLine2] = useState("");
  const [errContactTown, setErrContactTown] = useState("");
  const [errContactPostcode, setErrContactPostcode] = useState("");

  useEffect(() => {
    setHasErrors(false);
    setErrClientName("");
    setErrContactName("");
    setErrContactEmail("");
    setErrPaymentReference("");
    setErrPaymentAmount("");
    setErrPaymentDescription("");
    setErrContactTel("");
    setErrAccountNumber("");
    setErrContactLine1("");
    setErrContactLine2("");
    setErrContactTown("");
    setErrContactPostcode("");
  }, [
    clientName,
    accountNumber,
    contactName,
    contactEmail,
    contactLine1,
    contactLine2,
    contactTown,
    contactPostcode,
    contactTel,
    allowCard,
    paymentAmount,
    paymentReference,
    paymentDescription,
  ]);

  useEffect(() => {
    if (id !== "new") {
      loadPayment();
    }
  }, []);

  const loadPayment = () => {
    ConsoleAPI.getPayment(id, (data) => {
      setClientName(data.clientName);
      setContactName(data.contactName);
      setContactEmail(data.contactEmail);
      setAllowCard(data.allowCard);
      setPaymentAmount(data.paymentAmount);
      setPaymentReference(data.paymentReference);
      setPaymentDescription(data.paymentDescription);
      setAccountNumber(data.accountNumber);
      setContactTel(data.contactTel);
      setContactLine1(data.contactLine1);
      setContactLine2(data.contactLine2);
      setContactTown(data.contactTown);
      setContactPostcode(data.contactPostcode);
    });
  };

  const save = () => {
    var _ok = true;
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (clientName === "") {
      _ok = false;
      setHasErrors(true);
      setErrClientName("Please enter a client name");
    }
    if (paymentReference === "") {
      _ok = false;
      setHasErrors(true);
      setErrPaymentReference("Please enter the Invoice Number/Reference");
    }
    if (paymentDescription === "") {
      _ok = false;
      setHasErrors(true);
      setErrPaymentDescription("Please enter the payment description");
    }
    if (parseInt(paymentAmount) <= 0) {
      _ok = false;
      setHasErrors(true);
      setErrPaymentAmount("Please enter the payment amount");
    }
    if (contactName === "") {
      _ok = false;
      setHasErrors(true);
      setErrContactName("Please enter a contact name");
    }
    if (contactEmail === "") {
      _ok = false;
      setHasErrors(true);
      setErrContactEmail("Please enter the contact email");
    } else if (!re.test(contactEmail)) {
      _ok = false;
      setHasErrors(true);
      setErrContactEmail("Please enter a valid email address");
    }
    if (contactLine1 === "") {
      _ok = false;
      setHasErrors(true);
      setErrContactLine2("Please enter the contact address");
    }
    if (contactTown === "") {
      _ok = false;
      setHasErrors(true);
      setErrContactTown("Please enter the contact town");
    }
    if (contactPostcode === "") {
      _ok = false;
      setHasErrors(true);
      setErrContactPostcode("Please enter the contact postcode");
    }
    if (
      contactTel === "" ||
      (contactTel && (contactTel.length < 10 || contactTel.length > 20))
    ) {
      _ok = false;
      setHasErrors(true);
      setErrContactTel("Please enter a valid contact telephone number");
    }

    if (_ok) {
      let payload = {
        ID: id,
        clientName: clientName,
        contactName: contactName,
        contactEmail: contactEmail,
        paymentReference: paymentReference,
        paymentAmount: paymentAmount,
        allowCard: allowCard,
        paymentDescription: paymentDescription,
        accountNumber: accountNumber,
        contactTel: contactTel,
        contactLine1: contactLine1,
        contactLine2: contactLine2,
        contactTown: contactTown,
        contactPostcode: contactPostcode,
      };

      ConsoleAPI.updPayment(payload, (data) => {
        if (id === "new") {
          navigate("/payments");
        } else {
          navigate(`/payments/${id}`);
        }
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row row-eq-height">
        <div className="col-sm-12 content">
          <NarrowContainer>
            <BackButton url="/payments" title="Back" />
            <Title
              title={
                id === "new" ? "New Payment Request" : "Edit Payment Request"
              }
            />

            <FormContainer
              title="Client Information"
              description={`Please add the details for this payment.  The email address entered will receive an email requesting payment.`}
            >
              <div className="row">
                <div className="col-sm-6">
                  <TextInput
                    name="clientName"
                    type="text"
                    label="Client Name"
                    value={clientName}
                    maxLength={45}
                    asterix={true}
                    onChange={(name, value) => setClientName(value)}
                    error={errClientName}
                  />
                </div>
                <div className="col-sm-6">
                  <TextInput
                    name="accountNumber"
                    type="text"
                    label="Account Number"
                    value={accountNumber}
                    maxLength={45}
                    onChange={(name, value) => setAccountNumber(value)}
                    error={errAccountNumber}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <TextInput
                    name="contactName"
                    type="text"
                    label="Contact Name"
                    value={contactName}
                    maxLength={45}
                    asterix={true}
                    onChange={(name, value) => setContactName(value)}
                    error={errContactName}
                  />
                </div>
                <div className="col-sm-6">
                  <TextInput
                    name="contactEmail"
                    type="email"
                    label="Client Email"
                    value={contactEmail}
                    maxLength={45}
                    asterix={true}
                    onChange={(name, value) => setContactEmail(value)}
                    error={errContactEmail}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <TextInput
                    name="contactTel"
                    type="tel"
                    label="Contact Telephone Number"
                    value={contactTel}
                    maxLength={45}
                    asterix={true}
                    onChange={(name, value) => setContactTel(value)}
                    error={errContactTel}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <TextInput
                    name="contactLine1"
                    type="text"
                    label="Client Address"
                    placeholder="Address line 1"
                    value={contactLine1}
                    asterix={true}
                    onChange={(name, value) => setContactLine1(value)}
                    error={errContactLine1}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <TextInput
                    name="contactLine2"
                    type="text"
                    placeholder="Address line 2"
                    value={contactLine2}
                    onChange={(name, value) => setContactLine2(value)}
                    error={errContactLine2}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <TextInput
                    name="contactTown"
                    type="text"
                    placeholder="Town/City"
                    value={contactTown}
                    onChange={(name, value) => setContactTown(value)}
                    error={errContactTown}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <TextInput
                    name="contactPostcode"
                    type="text"
                    placeholder="Post Code"
                    value={contactPostcode}
                    onChange={(name, value) => setContactPostcode(value)}
                    error={errContactPostcode}
                  />
                </div>
              </div>
            </FormContainer>

            <FormContainer
              title="Payment Information"
              description={`Please add the details for this payment.  This function is used to send a request for payment to a specific email address.  New orders created under the "Orders" section will automatically create a payment request. The Invoice Number/Reference entered will be used by the client as the reference when making a BACS payment, or will automatically be added on the statement when a card payment is made.`}
            >
              <div className="row">
                <div className="col-sm-6">
                  <SwitchButton
                    label="Card Payments"
                    text="Allow Card payments?"
                    name="allowCard"
                    value={allowCard}
                    onChange={(name, value) => setAllowCard(value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <CurrencyInput
                    name="paymentAmount"
                    type="number"
                    label="Payment Amount"
                    placeholder="Enter payment amount..."
                    value={paymentAmount}
                    maxLength={45}
                    asterix={true}
                    onChange={(name, value) => setPaymentAmount(value)}
                    error={errPaymentAmount}
                  />
                </div>
                <div className="col-sm-6">
                  <TextInput
                    name="paymentReference"
                    type="text"
                    label="Invoice Number/Reference"
                    value={paymentReference}
                    maxLength={45}
                    asterix={true}
                    onChange={(name, value) => setPaymentReference(value)}
                    error={errPaymentReference}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <TextArea
                    name="paymentDescription"
                    label="Payment Description"
                    placeholder="Enter payment description..."
                    value={paymentDescription}
                    rows={3}
                    asterix={true}
                    onChange={(name, value) => setPaymentDescription(value)}
                    error={errPaymentDescription}
                  />
                </div>
              </div>
            </FormContainer>

            <Divider />

            <div className="row">
              <div className="col-sm-4">&nbsp;</div>
              <div className="col-sm-4" style={{ paddingBottom: 60 }}>
                <span className="invalid-error">
                  {hasErrors ? "Please check the errors above" : ""}
                </span>
              </div>
              <div className="col-sm-4 text-right">
                <SubmitButton
                  text="Send Payment Request"
                  className="btn"
                  onClick={save}
                />
              </div>
            </div>
          </NarrowContainer>
        </div>
      </div>
    </div>
  );
}

export default PaymentEditPage;
