import React from 'react';

class DataField extends React.Component {

    render() {
        return (
            <div className="form-group">
                <label className="sidebar-label">{this.props.label}</label>
                <div className="sidebar-text">{this.props.value} {this.props.link ? <span className="span-link" onClick={this.props.onClick}>{this.props.link}</span> : ''}</div>
            </div>
        )
    }

};

export default DataField;