import React from 'react';
import { Container } from '../../components/Container';
import { DataField, DataSet } from '../../components/Data';
import ConsoleAPI from '../../api/ConsoleAPI';
import moment from 'moment';
import AttachmentAPI from '../../api/AttachmentAPI';

class OrderSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            orderNumber: '',
            orderDate: '',
            orderStatus: 0,
            courierCode: '',
            clientName: '',
            clientPO: '',
            accountNumber: '',
            contactName: '',
            contactEmail: '',
            contactLine1: '',
            contactLine2: '',
            contactTown: '',
            contactPostcode: '',
            recipientCompany: '',
            recipientName: '',
            recipientEmail: '',
            recipientLine1: '',
            recipientLine2: '',
            recipientTown: '',
            recipientPostcode: '',
            copyAddress: false,
            orderItems: [],
            contactTel: '',
            fundsReceived: '',
            transRef: '',
            contactCountry: '',
            recipientCountry: '',
            recipientTel: '',
            campaignID: '',
            fileName: '',
        };
    }

    componentDidMount() {
        this.loadOrder();
        this.loadAttachments();
    }

    loadAttachments() {
        AttachmentAPI.getAttachments(this.state.id, (data) => {
            if (data.length > 0) {
                this.setState({
                    fileName: data[0].fileName
                });
            }
        });
    }

    loadOrder() {
        ConsoleAPI.getOneOffOrder(this.state.id, (data) => {
            this.setState({
                clientName: data.clientName,
                orderNumber: data.orderNumber,
                orderDate: data.orderDate,
                orderStatus: data.orderStatus,
                courierCode: data.courierCode,
                clientPO: data.clientPO,
                accountNumber: data.accountNumber,
                contactName: data.contactName,
                contactEmail: data.contactEmail,
                contactLine1: data.contactLine1,
                contactLine2: data.contactLine2,
                contactTown: data.contactTown,
                contactPostcode: data.contactPostcode,
                recipientCompany: data.recipientCompany,
                recipientName: data.recipientName,
                recipientEmail: data.recipientEmail,
                recipientLine1: data.recipientLine1,
                recipientLine2: data.recipientLine2,
                recipientTown: data.recipientTown,
                recipientPostcode: data.recipientPostcode,
                copyAddress: data.copyAddress,
                contactTel: data.contactTel,
                fundsReceived: data.fundsReceived,
                transRef: data.transRef,
                contactCountry: data.contactCountry,
                recipientCountry: data.recipientCountry,
                recipientTel: data.recipientTel,
                campaignID: data.campaignID,
            });
        });
    }


    render() {
        return (
            <Container>
                {(parseInt(this.state.orderStatus) === 2 || parseInt(this.state.orderStatus) === 3) &&
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="sidebar-label">Payment Details</label>
                                <div className="sidebar-text">{moment(this.state.fundsReceived).format("DD MMM YY")} - Ref: {this.state.transRef}</div>
                                {this.state.fileName !== '' &&
                                    <div><a target="_blank" rel="noopener noreferrer" href={`https://data.afbdata.com/uploads/${this.state.id}/${this.state.fileName}`}>Payment confirmation attachment</a></div>
                                }
                            </div>
                        </div>
                    </div>
                }
                {this.state.campaignID &&
                    <div className="row">
                        <div className="col-sm-12">
                            <DataField label="Marketing Campaign Code" value={this.state.campaignID} />
                        </div>
                    </div>
                }
                {this.state.courierCode &&
                    <div className="row">
                        <div className="col-sm-12">
                            <DataField label="Courier Code" value={this.state.courierCode} />
                        </div>
                    </div>
                }
                {this.state.accountNumber &&
                    <div className="row">
                        <div className="col-sm-12">
                            <DataField label="Client Account Number" value={this.state.accountNumber} />
                        </div>
                    </div>
                }
                {this.state.clientPO &&
                    <div className="row">
                        <div className="col-sm-12">
                            <DataField label="Client PO/Reference" value={this.state.clientPO} />
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-sm-12">
                        <DataSet label="Contact">
                            <p>{this.state.contactName}<br />
                                {this.state.clientName}<br />
                                {this.state.contactLine1}<br />
                                {this.state.contactLine2 ? <span>{this.state.contactLine2}<br /></span> : ''}
                                {this.state.contactTown}<br />
                                {this.state.contactPostcode}<br />
                                {this.state.contactCountry === 'IR' ? 'Republic of Ireland' : 'United Kingdom'}</p>
                            <p>{this.state.contactEmail}<br />
                                {this.state.contactTel}</p>
                        </DataSet>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <DataSet label="Recipient">
                            {this.state.copyAddress ?
                                <div>
                                    <p>{this.state.contactName}<br />
                                        {this.state.clientName}<br />
                                        {this.state.contactLine1}<br />
                                        {this.state.contactLine2 ? <span>{this.state.contactLine2}<br /></span> : ''}
                                        {this.state.contactTown}<br />
                                        {this.state.contactPostcode}<br />
                                        {this.state.contactCountry === 'IR' ? 'Republic of Ireland' : 'United Kingdom'}</p>
                                    <p>{this.state.contactEmail}<br />
                                        {this.state.contactTel}</p>
                                </div>
                                :
                                <div>
                                    <p>{this.state.recipientName}<br />
                                        {this.state.recipientCompany}<br />
                                        {this.state.recipientLine1}<br />
                                        {this.state.recipientLine2 ? <span>{this.state.recipientLine2}<br /></span> : ''}
                                        {this.state.recipientTown}<br />
                                        {this.state.recipientPostcode}<br />
                                        {this.state.recipientCountry === 'IR' ? 'Republic of Ireland' : 'United Kingdom'}</p>
                                    <p>{this.state.recipientEmail}<br />
                                        {this.state.recipientTel}</p>
                                </div>}
                        </DataSet>
                    </div>
                </div>
            </Container>
        )
    }

};

export default OrderSidebar;
