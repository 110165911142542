import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { Container } from "../../components/Container";
import { Title } from "../../components/Title";
import { SubmitButton, BackButton } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { SelectBox } from "../../components/SelectBox";
import UserAPI from "../../api/UserAPI";
import ConsoleAPI from "../../api/ConsoleAPI";
import { PaymentSidebar } from "../../components/Sidebar";
import AttachmentAPI from "../../api/AttachmentAPI";

function PaymentFundsPage({ navigation }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [clientName, setClientName] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentID, setPaymentID] = useState("");
  const [isPaid, setIsPaid] = useState(false);

  const [confirmedBy, setConfirmedBy] = useState("");
  const [confirmedByName, setConfirmedByName] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const [errPaymentMethod, setErrPaymentMethod] = useState("");
  const [errFundsReceived, setErrFundsReceived] = useState("");

  const [fundsReceived, setFundsReceived] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [transRef, setTransRef] = useState("");

  useEffect(() => {
    loadPayment();
    loadAttachments();
    UserAPI.getUserData((data) => {
      if (data) {
        setConfirmedBy(data.userToken);
        setConfirmedByName(data.firstName + " " + data.lastName);
      }
    });
  }, []);

  const loadAttachments = () => {
    AttachmentAPI.getAttachments(id, (data) => {
      setAttachments(data);
    });
  };

  const attach = () => {
    var formData = new FormData();
    formData.append("file", selectedFile);
    AttachmentAPI.addAttachment(id, formData, (res) => {
      loadAttachments();
    });
  };

  const onChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const loadPayment = () => {
    ConsoleAPI.getPayment(id, (data) => {
      setClientName(data.clientName);
      //setContactName(data.contactName);
      //setContactEmail(data.contactEmail);
      //setAllowCard(data.allowCard);
      //setPaymentAmount(data.paymentAmount);
      //setPaymentReference(data.paymentReference);
      //setPaymentDescription(data.paymentDescription);
      //setAccountNumber(data.accountNumber);
      //setContactTel(data.contactTel);
      //setContactLine1(data.contactLine1);
      //setContactLine2(data.contactLine2);
      //setContactTown(data.contactTown);
      //setContactPostcode(data.contactPostcode);
      setPaymentID(data.paymentID);
      setIsPaid(data.isPaid);
    });
  };

  const confirm = () => {
    var _ok = true;
    if (paymentMethod === 0) {
      _ok = false;
      setErrPaymentMethod("Please select the payment method");
    }
    /*
        if (transRef === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errTransRef: 'Please enter the transaction reference'
            });
        }
        */
    var date = moment(fundsReceived);
    if (!date.isValid()) {
      _ok = false;
      setErrFundsReceived("Please select a valid date");
    }
    /*
        if (attachments.length === 0) {
            _ok = false;
            this.setState({
                hasErrors: true,
                fileError: true,
            });
        }
        */

    if (_ok) {
      let payload = {
        ID: id,
        confirmedBy: confirmedBy,
        paymentMethod: paymentMethod,
        fundsReceived: fundsReceived,
        transRef: transRef,
      };

      ConsoleAPI.updPaymentReceipt(payload, (data) => {
        navigate(`/payments/${id}`);
      });
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 content">
          <BackButton title="Back" url={`/payments/${id}`} />
          <Title title={`DPP${paymentID} - ${clientName}`} />
          <div>
            {isPaid ? (
              <span
                style={{ marginTop: -20, marginRight: 20 }}
                className="grey-warning"
              >
                Paid
              </span>
            ) : (
              <span
                style={{ marginTop: -20, marginRight: 20 }}
                className="yellow-warning"
              >
                Awaiting Payment
              </span>
            )}{" "}
            {moment().format("dddd DD MMMM YYYY - HH:mm")}
          </div>
        </div>
      </div>
      <div className="row row-eq-height">
        <div className="col-sm-8 content">
          <Container>
            <div className="row">
              <div className="col-sm-12">
                <h4 style={{ fontWeight: "bold", color: "#333" }}>
                  Confirm Funds Received
                </h4>
                <div className="row">
                  <div className="col-sm-6">
                    <SelectBox
                      name="paymentMethod"
                      label="Payment Method"
                      placeholder="Select..."
                      value={paymentMethod}
                      maxLength={45}
                      asterix={true}
                      options={[
                        {
                          value: "1",
                          label: "BACS",
                        },
                        {
                          value: "2",
                          label: "Credit/Debit Card",
                        },
                      ]}
                      onChange={(name, value) => setPaymentMethod(value)}
                      error={errPaymentMethod}
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextInput
                      name="confirmedByName"
                      type="text"
                      label="Confirmed By"
                      value={confirmedByName}
                      disabled={true}
                      maxLength={45}
                      asterix={true}
                      onChange={(name, value) => setConfirmedByName(value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <TextInput
                      name="fundsReceived"
                      type="date"
                      label="Date Received"
                      value={fundsReceived}
                      maxLength={45}
                      asterix={true}
                      onChange={(name, value) => setFundsReceived(value)}
                      error={errFundsReceived}
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextInput
                      name="transRef"
                      type="text"
                      label="Transaction Reference"
                      placeholder="Enter reference..."
                      value={transRef}
                      maxLength={45}
                      onChange={(name, value) => setTransRef(value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Container>

          <Container>
            <div className="row">
              <div className="col-sm-12">
                <h4 style={{ fontWeight: "bold", color: "#333" }}>
                  Attached Files/Images
                </h4>
                <div className="row">
                  <div className="col-sm-12">
                    {attachments.length > 0 && (
                      <div className="row">
                        <div className="col-sm-12">
                          <table>
                            <thead>
                              <tr>
                                <th>Added</th>
                                <th>Filename</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              {attachments.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    {moment(item.dateAdded).format(
                                      "ddd DD MMM YY @ HH:mm"
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`https://data.afbdata.com/uploads/${id}/${item.fileName}`}
                                    >
                                      {item.fileName}
                                    </a>
                                  </td>
                                  <td>
                                    <div
                                      className="delete-item"
                                      onClick={(e) => {
                                        if (
                                          window.confirm(
                                            "Are you sure you wish to delete this item?"
                                          )
                                        )
                                          this.deleteAttachment(item.ID);
                                      }}
                                    >
                                      <i className="far fa-times-circle"></i>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            type="file"
                            name="file"
                            className="form-control"
                            onChange={onChangeHandler}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <SubmitButton
                          text={"Upload Attachment"}
                          className="btn btn-secondary"
                          onClick={attach}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>

          <div className="row">
            <div className="col-sm-12 text-right">
              <SubmitButton
                text="Confirm Funds Received"
                className="btn"
                onClick={confirm}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-4 content">
          <PaymentSidebar id={id} />
        </div>
      </div>
    </div>
  );
}

export default PaymentFundsPage;
