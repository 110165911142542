import React from "react";
import { Link } from "react-router-dom";

const BackButton = ({ url, title }) => {
  return (
    <Link className="back" to={url}>
      <i className="fas fa-chevron-left"></i>
      {title}
    </Link>
  );
};

export default BackButton;
