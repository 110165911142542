import React from 'react';

class SelectBox extends React.Component {

    onSelectChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.props.onChange(name, value);
    }

    render() {
        return (
            <div className="ln-c-form-group">
                {this.props.label ? <label className="ln-c-label" htmlFor={this.props.name}>{this.props.label}{this.props.asterix ? <span className="asterix">*</span> : ''}&nbsp;</label> : ''}
                <select
                    id={this.props.name}
                    className="ln-c-select"
                    name={this.props.name}
                    value={this.props.value}
                    onChange={this.onSelectChange.bind(this)}
                    autoComplete="off"
                    disabled={this.props.disabled ? this.props.disabled : false}
                >
                    {this.props.placeholder &&
                        <option value="">{this.props.placeholder}</option>
                    }
                    {this.props.options.map((option, index) =>
                        <option key={index} value={option.value}>{option.label}</option>
                    )}
                </select>
                {this.props.error ? <div className="invalid-error">{this.props.error}</div> : ''}
            </div>
        )
    }

};

export default SelectBox;