import React from 'react';
import { Container } from '../../components/Container';
import { DataField, DataSet } from '../../components/Data';
import ConsoleAPI from '../../api/ConsoleAPI';
import moment from 'moment';

class PaymentSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            clientName: '',
            contactName: '',
            contactEmail: '',
            allowCard: true,
            paymentAmount: 0,
            paymentReference: '',
            paymentDescription: '',
            accountNumber: '',
            contactTel: '',
            contactLine1: '',
            contactLine2: '',
            contactTown: '',
            contactPostcode: '',
            created: '',
            paymentID: '',
            isPaid: false,
            transRef: ''
        };
    }

    componentDidMount() {
        this.loadPayment();
    }

    loadPayment() {
        ConsoleAPI.getPayment(this.state.id, (data) => {
            this.setState({
                clientName: data.clientName,
                contactName: data.contactName,
                contactEmail: data.contactEmail,
                allowCard: data.allowCard,
                paymentAmount: data.paymentAmount,
                paymentReference: data.paymentReference,
                paymentDescription: data.paymentDescription,
                accountNumber: data.accountNumber,
                contactTel: data.contactTel,
                contactLine1: data.contactLine1,
                contactLine2: data.contactLine2,
                contactTown: data.contactTown,
                contactPostcode: data.contactPostcode,
                created: data.created,
                paymentID: data.paymentID,
                isPaid: data.isPaid,
                transRef: data.transRef
            })
        })
    }


    render() {
        return (
            <Container>
                <div className="row">
                    <div className="col-sm-12">
                        <DataField label="Payment Amount" value={`£${parseFloat(this.state.paymentAmount).toFixed(2)}`} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <DataField label="Description" value={this.state.paymentDescription} />
                    </div>
                </div>


                {this.state.isPaid &&
                    <div className="row">
                        <div className="col-sm-12">
                            <DataField label="Payment Details" value={`${moment(this.state.fundsReceived).format("DD MMM YY")} - Ref: ${this.state.transRef}`} />
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-sm-12">
                        <DataField label="Client Name" value={this.state.clientName} />
                    </div>
                </div>
                {this.state.accountNumber &&
                    <div className="row">
                        <div className="col-sm-12">
                            <DataField label="Client Account Number" value={this.state.accountNumber} />
                        </div>
                    </div>
                }
                {this.state.clientPO &&
                    <div className="row">
                        <div className="col-sm-12">
                            <DataField label="Invoice Number/Reference" value={this.state.paymentAmount} />
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-sm-12">
                        <DataSet label="Contact">
                            <p>{this.state.contactName}<br />
                                {this.state.contactEmail}<br />
                                {this.state.contactTel}</p>
                            <p>{this.state.contactLine1}<br />
                                {this.state.contactLine2 ? <span>{this.state.contactLine2}<br /></span> : ''}
                                {this.state.contactTown}<br />
                                {this.state.contactPostcode}<br />
                                {this.state.contactCountry === 'IR' ? 'Republic of Ireland' : 'United Kingdom'}</p>
                        </DataSet>
                    </div>
                </div>
            </Container>
        )
    }

};

export default PaymentSidebar;