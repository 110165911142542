import React from "react";
import { Link, useLocation } from "react-router-dom";

const Tabs = ({ tabs }) => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <div className="tabs">
      {tabs.map((tab, index) => (
        <div key={index} className={path === tab.url ? "tab active" : "tab"}>
          <Link to={tab.url}>{tab.label}</Link>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
