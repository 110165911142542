import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { Container } from "../../components/Container";
import { Title } from "../../components/Title";
import { SubmitButton, BackButton } from "../../components/Button";
import { TextInput, TextArea } from "../../components/TextInput";
import ConsoleAPI from "../../api/ConsoleAPI";
import { OrderSidebar } from "../../components/Sidebar";
import UserAPI from "../../api/UserAPI";
import NotesAPI from "../../api/NotesAPI";

function OrderPage({ navigation }) {
  const navigate = useNavigate();

  const { id } = useParams();

  const [orderNumber, setOrderNumber] = useState("");
  const [orderDate, setOrderDate] = useState("");

  const [userToken, setUserToken] = useState("");
  const [clientName, setClientName] = useState("");
  const [vexSent, setVexSent] = useState(false);
  const [vexSentDate, setVexSentDate] = useState(null);
  const [orderStatus, setOrderStatus] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [discountRate, setDiscountRate] = useState(0);
  const [orderItems, setOrderItems] = useState([]);
  const [historyData, setHistory] = useState([]);
  const [habitatDiscountValue, setHabitatDiscountValue] = useState(0);
  const [habitatDiscountRate, setHabitatDiscountRate] = useState(0);
  const [sainsburysDiscountValue, setSainsburysDiscountValue] = useState(0);
  const [sainsburysDiscountRate, setSainsburysDiscountRate] = useState(0);
  const [multiDiscountValue, setMultiDiscountValue] = useState(0);
  const [multiDiscountRate, setMultiDiscountRate] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [permCashSalesOrders, setPermCashSalesOrders] = useState(false);
  const [permCashSalesFunds, setPermCashSalesFunds] = useState(false);
  const [permCashSalesFundsHigher, setPermCashSalesFundsHigher] =
    useState(false);
  const [noteBody, setNoteBody] = useState("");
  const [reModal, setReModal] = useState(false);
  const [reEmail, setReEmail] = useState("");
  const [fullOrder, setFullOrder] = useState({});

  useEffect(() => {
    loadUser();
    loadOrder();
    loadOrderItems();
    loadHistory();
  }, []);

  const loadHistory = () => {
    ConsoleAPI.getOneOffOrderHistory(id, (data) => {
      setHistory(data);
      setNoteBody("");
    });
  };

  const resendEmail = () => {
    ConsoleAPI.resendEmail(id, reEmail, (data) => {
      setReModal("");
      setReModal(false);
      loadHistory();
    });
  };

  const loadUser = () => {
    UserAPI.getUserData((data) => {
      if (data) {
        setUserToken(data.userToken);
        setPermCashSalesFunds(data.permCashSalesFunds);
        setPermCashSalesFundsHigher(data.permCashSalesFundsHigher);
        setPermCashSalesOrders(data.permCashSalesOrders);
        UserAPI.checkUserPermissions(data.userToken, "cashsales", (perm) => {
          if (!perm) {
            navigate("/");
          }
        });
      }
    });
  };

  const loadOrder = () => {
    ConsoleAPI.getOneOffOrder(id, (data) => {
      setFullOrder(data);
      setClientName(data.clientName);
      setOrderNumber(data.orderNumber);
      setOrderDate(data.orderDate);
      setOrderStatus(data.orderStatus);
      //setClientPO(data.clientPO);
      //setAccountNumber(data.accountNumber);
      //setContactName(data.contactName);
      //setContactEmail(data.contactEmail);
      setReEmail(data.contactEmail);
      //setContactLine1(data.contactLine1);
      //setContactLine2(data.contactLine2);
      //setContactTown(data.contactTown);
      //setContactPostcode(data.contactPostcode);
      //setRecipientName(data.recipientName);
      //setRecipientEmail(data.recipientEmail);
      //setRecipientLine1(data.recipientLine1);
      //setRecipientLine2(data.recipientLine2);
      //setRecipientTown(data.recipientTown);
      //setRecipientPostcode(data.recipientPostcode);
      //setCopyAddress(data.copyAddress);
      setTotalCost(data.totalCost);
      setDiscountValue(data.discountValue);
      setDiscountRate(data.discountRate);
      setSainsburysDiscountRate(data.sainsburysDiscountRate);
      setSainsburysDiscountValue(data.sainsburysDiscountValue);
      setHabitatDiscountRate(data.habitatDiscountRate);
      setHabitatDiscountValue(data.habitatDiscountValue);
      setMultiDiscountRate(data.multiDiscountRate);
      setMultiDiscountValue(data.multiDiscountValue);
      setDiscount(data.discount);
      setVexSent(data.vexSent);
      setVexSentDate(data.vexSentDate);
    });
  };

  const cancelOrder = () => {
    ConsoleAPI.getOneOffOrder(id, (data) => {
      let payload = fullOrder;
      payload.orderStatus = 4;
      payload.items = orderItems;
      payload.addedBy = userToken;
      console.log(payload);

      ConsoleAPI.updOneOffOrder(payload, (data) => {
        navigate(`/orders/${id}`);
      });
    });
  };

  const loadOrderItems = () => {
    ConsoleAPI.getOneOffOrderItems(id, (data) => {
      setOrderItems(data);
    });
  };

  const fundsReceived = () => {
    navigate(`/orders/funds/${id}`);
  };

  const editOrder = () => {
    navigate(`/orders/edit/${id}`);
  };

  const cancelFundsReceived = () => {
    let payload = {
      ID: id,
      confirmedBy: userToken,
      paymentMethod: -1,
    };
    ConsoleAPI.updOneOffOrderPayment(payload, (data) => {
      navigate(`/orders/${id}`);
    });
  };

  const duplicate = () => {
    navigate(`/orders/edit/new?copy=${id}`);
  };

  const addNote = () => {
    if (noteBody !== "") {
      let payload = {
        linkedId: id,
        authorId: userToken,
        noteBody: noteBody,
      };
      NotesAPI.addNote(payload, (data) => {
        loadHistory();
      });
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 content">
          <BackButton title="Back" url="/orders" />
          <Title title={`DPI${orderNumber} - ${clientName}`} />
          <div className="row">
            <div className="col-sm-8">
              {orderStatus === 4 ? (
                <span
                  className="grey-warning"
                  style={{ marginTop: -20, marginRight: 20 }}
                >
                  Cancelled
                </span>
              ) : orderStatus === 3 ? (
                <span
                  className="grey-warning"
                  style={{ marginTop: -20, marginRight: 20 }}
                >
                  Despatched
                </span>
              ) : orderStatus === 2 ? (
                <span
                  className="grey-warning"
                  style={{ marginTop: -20, marginRight: 20 }}
                >
                  Payment Received
                </span>
              ) : orderStatus === 5 ? (
                <span
                  className="red-warning"
                  style={{ marginTop: -20, marginRight: 20 }}
                >
                  Payment Made
                </span>
              ) : (
                <span
                  className="yellow-warning"
                  style={{ marginTop: -20, marginRight: 20 }}
                >
                  Awaiting Payment
                </span>
              )}{" "}
              Created: {moment(orderDate).format("dddd DD MMMM YYYY - HH:mm")}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 order-buttons" style={{ paddingTop: 20 }}>
          {permCashSalesOrders && ![2, 3, 4].includes(orderStatus) && (
            <div style={{ display: "inline", marginRight: 20 }}>
              <SubmitButton text="Change Order" onClick={editOrder} />
            </div>
          )}

          {permCashSalesOrders && id !== "new" && (
            <div style={{ display: "inline", marginRight: 20 }}>
              <SubmitButton text="Copy Order" onClick={duplicate} />
            </div>
          )}

          {permCashSalesOrders && ![2, 3, 4].includes(orderStatus) && (
            <div style={{ display: "inline", marginRight: 20 }}>
              <SubmitButton
                text="Cancel Order"
                onClick={(e) => {
                  if (
                    window.confirm(
                      "Are you sure you wish to cancel this order?"
                    )
                  )
                    cancelOrder();
                }}
              />
            </div>
          )}

          {orderStatus === 2 && !vexSent && (
            <div style={{ display: "inline", marginRight: 20 }}>
              <SubmitButton
                text="Cancel Receipt"
                onClick={cancelFundsReceived}
              />
            </div>
          )}

          {orderStatus === 1 && (
            <div style={{ display: "inline", marginRight: 20 }}>
              <SubmitButton
                text="Resend Email"
                onClick={() => setReModal(true)}
              />
            </div>
          )}

          {((!vexSent && permCashSalesFunds && totalCost - discount < 5000) ||
            (permCashSalesFundsHigher && totalCost - discount >= 5000)) &&
            ![2, 3, 4].includes(orderStatus) && (
              <div style={{ display: "inline", marginRight: 20 }}>
                <SubmitButton text="Funds Received" onClick={fundsReceived} />
              </div>
            )}
        </div>
      </div>
      <div className="row row-eq-height">
        <div className="col-sm-8 content">
          <Container>
            <div className="row">
              <div className="col-sm-12">
                <h4 style={{ fontWeight: "bold", color: "#333" }}>
                  Order Details
                </h4>

                <table>
                  <thead>
                    <tr>
                      <th width="50%">Product Type</th>
                      <th width="25%">Card Quantity</th>
                      <th width="25%">Card Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderItems.map((item, index) => (
                      <tr key={index}>
                        <td>{item.productName}</td>
                        <td>{item.qty}</td>
                        <td>&pound;{item.value.toFixed(2)}</td>
                      </tr>
                    ))}

                    <tr style={{ borderBottom: 0 }}>
                      <td>&nbsp;</td>
                      <td>Cost:</td>
                      <td>&pound;{parseFloat(totalCost).toFixed(2)}</td>
                    </tr>
                    {discountValue > 0 && (
                      <tr style={{ borderBottom: 0 }}>
                        <td>&nbsp;</td>
                        <td>
                          Argos Discount ({parseFloat(discountRate).toFixed(2)}
                          %):
                        </td>
                        <td>&pound;{parseFloat(discountValue).toFixed(2)}</td>
                      </tr>
                    )}
                    {sainsburysDiscountValue > 0 && (
                      <tr style={{ borderBottom: 0 }}>
                        <td>&nbsp;</td>
                        <td>
                          Sainsbury's Discount (
                          {parseFloat(sainsburysDiscountRate).toFixed(2)}%):
                        </td>
                        <td>
                          &pound;
                          {parseFloat(sainsburysDiscountValue).toFixed(2)}
                        </td>
                      </tr>
                    )}
                    {habitatDiscountValue > 0 && (
                      <tr style={{ borderBottom: 0 }}>
                        <td>&nbsp;</td>
                        <td>
                          Habitat Discount (
                          {parseFloat(habitatDiscountRate).toFixed(2)}%):
                        </td>
                        <td>
                          &pound;{parseFloat(habitatDiscountValue).toFixed(2)}
                        </td>
                      </tr>
                    )}
                    {multiDiscountValue > 0 && (
                      <tr style={{ borderBottom: 0 }}>
                        <td>&nbsp;</td>
                        <td>
                          Multi Brand Discount (
                          {parseFloat(multiDiscountRate).toFixed(2)}%):
                        </td>
                        <td>
                          &pound;{parseFloat(multiDiscountValue).toFixed(2)}
                        </td>
                      </tr>
                    )}
                    <tr style={{ borderBottom: 0 }}>
                      <td>&nbsp;</td>
                      <td>
                        <strong>Total Cost:</strong>
                      </td>
                      <td>
                        <strong>
                          &pound;{parseFloat(totalCost - discount).toFixed(2)}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Container>

          <Container>
            <div className="row">
              <div className="col-sm-12">
                <h4 style={{ fontWeight: "bold", color: "#333" }}>
                  Notes &amp; History
                </h4>
                <table>
                  <thead>
                    <tr>
                      <th width="20%">Date</th>
                      <th width="80%" className="text-cell">
                        Description
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {moment(item.timeStamp).format("DD MMM YY HH:mm")}
                        </td>
                        <td
                          className="text-cell force-wrap"
                          dangerouslySetInnerHTML={{ __html: item.comments }}
                        ></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <TextArea
                  name="noteBody"
                  type="text"
                  label="Add New Note"
                  value={noteBody}
                  rows={3}
                  onChange={(name, value) => setNoteBody(value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <SubmitButton
                  text="Add Note"
                  className="btn btn-secondary"
                  onClick={addNote}
                />
              </div>
            </div>
          </Container>
        </div>
        <div className="col-sm-4 content">
          <OrderSidebar id={id} />

          {orderStatus === 2 && vexSent && (
            <div className="container-box text-center">
              Order sent for Delivery:
              <br />
              {moment(vexSentDate).format("DD/MM/YYYY HH:mm")}
            </div>
          )}
        </div>
      </div>

      {reModal && (
        <>
          <div className="modal-shadow"></div>
          <div className="incident-modal">
            <div className="ms-close">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                onClick={() => setReModal(false)}
              >
                <path
                  fillRule="evenodd"
                  d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div className="incident-form">
              <>
                <div className="row">
                  <div className="col-sm-12">
                    <TextInput
                      name="reEmail"
                      type="email"
                      label="Recipient Email"
                      value={reEmail}
                      maxLength={45}
                      asterix={true}
                      onChange={(name, value) => setReEmail(value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 py-4">
                    <button
                      className="ln-c-button ln-c-button--filled"
                      onClick={resendEmail}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default OrderPage;
