import apiRequest from '.';

class ConsoleAPI {

    getOneOffOrders(keyword, from, to, status, page, size, cb) {
        apiRequest({ endpoint: `console/oneofforders?page=${page}&size=${size}&keyword=${keyword}&from=${from}&to=${to}&status=${status}` }, cb);
    }

    getOneOffOrder(id, cb) {
        apiRequest({ endpoint: `console/oneofforder?id=${id}` }, cb);
    }

    getOneOffOrderItems(id, cb) {
        apiRequest({ endpoint: `console/oneofforderitems?id=${id}` }, cb);
    }

    getSettings(cb) {
        apiRequest({ endpoint: `console/settings` }, cb);
    }

    getOneOffOrderHistory(id, cb) {
        apiRequest({ endpoint: `console/oneofforderhistory?id=${id}` }, cb);
    }

    getPayments(keyword, from, to, status, page, size, cb) {
        apiRequest({ endpoint: `console/payments?page=${page}&size=${size}&keyword=${keyword}&from=${from}&to=${to}&status=${status}` }, cb);
    }

    getPayment(id, cb) {
        apiRequest({ endpoint: `console/payment?id=${id}` }, cb);
    }

    getPaymentHistory(id, cb) {
        apiRequest({ endpoint: `console/paymenthistory?id=${id}` }, cb);
    }

    updPayment(payload, cb) {
        apiRequest({ endpoint: `console/payment`, method: 'POST', payload }, cb);
    }

    updOneOffOrder(payload, cb) {
        apiRequest({ endpoint: `console/oneofforder`, method: 'POST', payload }, cb);
    }

    updOneOffOrderPayment(payload, cb) {
        apiRequest({ endpoint: `console/oneofforderpayment`, method: 'POST', payload }, cb);
    }

    updPaymentReceipt(payload, cb) {
        apiRequest({ endpoint: `console/paymentreceipt`, method: 'POST', payload }, cb);
    }

    getOneOffOrderExtract(cb) {
        apiRequest({ endpoint: `console/oneofforderextract` }, cb);
    }

    getSearch(keyword, cb) {
        apiRequest({ endpoint: `console/search?keyword=${keyword}` }, cb);
    }

    getAccountManagers(cb) {
        apiRequest({ endpoint: `console/accountmanagers` }, cb);
    }

    getProducts(cb) {
        apiRequest({ endpoint: `console/products` }, cb);
    }

    validateOrder(payload, cb) {
        apiRequest({ endpoint: `console/validateorder`, method: 'POST', payload }, cb);
    }

    exportUserPermissions(cb) {
        apiRequest({ endpoint: `console/permissions` }, cb);
    }

    exportOrders(keyword, from, to, status, cb) {
        apiRequest({ endpoint: `console/orderexport?keyword=${keyword}&from=${from}&to=${to}&status=${status}` }, cb);
    }

    exportPayments(keyword, from, to, status, cb) {
        apiRequest({ endpoint: `console/paymentexport?keyword=${keyword}&from=${from}&to=${to}&status=${status}` }, cb);
    }

    updSettings(payload, cb) {
        apiRequest({ endpoint: `console/settings`, method: 'POST', payload }, cb);
    }

    resendEmail(id, email, cb) {
        apiRequest({ endpoint: `console/OrderEmail?id=${id}&email=${email}`, method: 'POST', id }, cb);
    }

    getCostPlusTest(target, product, cb) {
        apiRequest({ endpoint: `console/costplustest?target=${target}&product=${product}` }, cb);
    }

    logIncident(payload, cb) {
        apiRequest({ endpoint: `core/incident`, method: 'POST', payload }, cb);
    }

    getDiscountRates(id, cb) {
        apiRequest({ endpoint: `console/discountrates?id=${id}` }, cb);
    }

    getHistory(id, cb) {
        apiRequest({ endpoint: `console/history?id=${id}` }, cb);
    }

    updDiscountRate(id, product, rate, cb) {
        apiRequest({ endpoint: `console/discountrates?id=${id}&product=${product}&rate=${rate}`, method: 'POST', id }, cb);
    }

    getReportTypes(cb) {
        apiRequest({ endpoint: `console/reporttypes` }, cb);
    }

    getReportFiles(type, from, to, cb) {
        apiRequest({ endpoint: `console/reportfiles?type=${type}&from=${from}&to=${to}` }, cb);
    }

    getReportFile(id, cb) {
        apiRequest({ endpoint: `console/reportfile?id=${id}` }, cb);
    }

}

export default new ConsoleAPI();