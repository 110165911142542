import apiRequest from '.';

class ClientAPI {

    getClients(cb) {
        let url = 'dataset';
        apiRequest({ endpoint: url }, cb);
    }

    getClient(id, cb) {
        apiRequest({ endpoint: `dataset/details?id=${id}` }, cb);
    }

    updClient(payload, cb) {
        apiRequest({ endpoint: `dataset/update`, method: 'POST', payload }, cb);
    }

    getLogs(id, type, cb) {
        apiRequest({ endpoint: `dataset/filelog?id=${id}&type=${type}` }, cb);
    }

    updExport(payload, cb) {
        apiRequest({ endpoint: `dataset/updateexport`, method: 'POST', payload }, cb);
    }

    updOrder(payload, cb) {
        apiRequest({ endpoint: `dataset/updateorder`, method: 'POST', payload }, cb);
    }

}

export default new ClientAPI();