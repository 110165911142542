import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormContainer, NarrowContainer } from "../../components/Container";
import { Title } from "../../components/Title";
import { Divider } from "../../components/Divider";
import { SubmitButton } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { SelectBox } from "../../components/SelectBox";
import ConsoleAPI from "../../api/ConsoleAPI";

function SettingsPage({ navigation }) {
  const navigate = useNavigate();

  const [alertMessage1, setAlertMessage1] = useState("");
  const [alertType1, setAlertType1] = useState("");
  const [alertMessage2, setAlertMessage2] = useState("");
  const [alertType2, setAlertType2] = useState("");
  const [alertMessage3, setAlertMessage3] = useState("");
  const [alertType3, setAlertType3] = useState("");

  const loadSettings = () => {
    ConsoleAPI.getSettings((data) => {
      console.log(data);

      setAlertMessage1(data.alertMessage1);
      setAlertType1(data.alertType1);
      setAlertMessage2(data.alertMessage2);
      setAlertType2(data.alertType2);
      setAlertMessage3(data.alertMessage3);
      setAlertType3(data.alertType3);
    });
  };

  useEffect(() => {
    loadSettings();
  }, []);

  const save = () => {
    let payload = {
      alertMessage1: alertMessage1,
      alertType1: alertType1,
      alertMessage2: alertMessage2,
      alertType2: alertType2,
      alertMessage3: alertMessage3,
      alertType3: alertType3,
    };

    ConsoleAPI.updSettings(payload, (data) => {
      navigate("/");
    });
  };

  return (
    <div className="container-fluid">
      <div className="row row-eq-height">
        <div className="col-sm-12 content">
          <NarrowContainer>
            <Title title={"Admin Settings"} />

            <FormContainer title="Alert Settings" description={``}>
              <div className="row">
                <div className="col-sm-8">
                  <TextInput
                    name="alertMessage1"
                    type="text"
                    label="Alert Message"
                    value={alertMessage1}
                    maxLength={500}
                    asterix={true}
                    onChange={(name, value) => setAlertMessage1(value)}
                  />
                </div>
                <div className="col-sm-4">
                  <SelectBox
                    name="alertType1"
                    label="Alert Type"
                    placeholder="Select..."
                    value={alertType1}
                    maxLength={45}
                    options={[
                      {
                        value: "red",
                        label: "Red",
                      },
                      {
                        value: "amber",
                        label: "Amber",
                      },
                      {
                        value: "green",
                        label: "Green",
                      },
                    ]}
                    onChange={(name, value) => setAlertType1(value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-8">
                  <TextInput
                    name="alertMessage2"
                    type="text"
                    label="Alert Message"
                    value={alertMessage2}
                    maxLength={500}
                    asterix={true}
                    onChange={(name, value) => setAlertMessage2(value)}
                  />
                </div>
                <div className="col-sm-4">
                  <SelectBox
                    name="alertType2"
                    label="Alert Type"
                    placeholder="Select..."
                    value={alertType2}
                    maxLength={45}
                    options={[
                      {
                        value: "red",
                        label: "Red",
                      },
                      {
                        value: "amber",
                        label: "Amber",
                      },
                      {
                        value: "green",
                        label: "Green",
                      },
                    ]}
                    onChange={(name, value) => setAlertType2(value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-8">
                  <TextInput
                    name="alertMessage3"
                    type="text"
                    label="Alert Message"
                    value={alertMessage3}
                    maxLength={500}
                    asterix={true}
                    onChange={(name, value) => setAlertMessage3(value)}
                  />
                </div>
                <div className="col-sm-4">
                  <SelectBox
                    name="alertType3"
                    label="Alert Type"
                    placeholder="Select..."
                    value={alertType3}
                    maxLength={45}
                    options={[
                      {
                        value: "red",
                        label: "Red",
                      },
                      {
                        value: "amber",
                        label: "Amber",
                      },
                      {
                        value: "green",
                        label: "Green",
                      },
                    ]}
                    onChange={(name, value) => setAlertType3(value)}
                  />
                </div>
              </div>
            </FormContainer>

            <Divider />

            <div className="row">
              <div className="col-sm-4">&nbsp;</div>
              <div className="col-sm-4" style={{ paddingBottom: 60 }}>
                &nbsp;
              </div>
              <div className="col-sm-4 text-right">
                <SubmitButton
                  text="Save Settings"
                  className="btn"
                  onClick={save}
                />
              </div>
            </div>
          </NarrowContainer>
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
