import React, { Component } from 'react';
import moment from 'moment';
import { FormContainer, NarrowContainer } from '../../components/Container';
import { Title } from '../../components/Title';
import { Divider } from '../../components/Divider';
import { BackButton } from '../../components/Button';
import UserAPI from '../../api/UserAPI';
import ClientAPI from '../../api/ClientAPI';
import { ClientMenu } from '../../components/Menu';
import { Loader } from '../../components/Loader';
import { Notes } from '../../components/Notes';

class ClientLogsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match && this.props.match.params.id,
            setName: '',
            files: [],
            osr: []
        };
    }

    componentDidMount() {

        UserAPI.getUserData((data) => {
            if (data) {
                this.setState({
                    userToken: data.userToken
                });
            }
        })

        if (this.state.id !== 'new') {
            this.loadClient();
            this.loadSFTPLog();
        }

    }

    loadSFTPLog() {
        ClientAPI.getLogs(this.state.id, "SFTP", (data) => {
            this.setState({
                files: data
            });
        })
    }

    loadClient() {
        ClientAPI.getClient(this.state.id, (data) => {
            console.log("getClient()", data);
            this.setState({
                setName: data.setName,
            });
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
            hasErrors: false,
            errSetName: '',
            errBusinessAccNo: ''
        });
    }

    cancel() {
        this.props.history.push('/clients');
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <NarrowContainer>

                            <BackButton url={'/clients'} title="Back" />
                            <Title title={"Notes & Logs"} />

                            <ClientMenu id={this.state.id} />

                            <Notes id={this.state.id} />

                            <Divider />

                            <FormContainer title="Order File Log"
                                description={`A log of order files received via SFTP.`}>
                                <div className="row">
                                    <div className="col-sm-12">

                                        {this.state.isLoading ?
                                            <Loader />
                                            :
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th width="30%">Received</th>
                                                        <th width="70%" className="text-cell">File Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.files.length === 0 &&
                                                        <tr>
                                                            <td colSpan="2" className="text-center">No records found.</td>
                                                        </tr>
                                                    }
                                                    {this.state.files.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{moment(item.dateAdded).format("ddd DD MMM YY @ HH:mm")}</td>
                                                            <td className="text-cell">{item.fileName}</td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>
                                        }

                                    </div>
                                </div>

                            </FormContainer>

                            <Divider />

                            <FormContainer title="OSR Log"
                                description={`A log of order status reports sent to the client via SFTP.`}>
                                <div className="row">
                                    <div className="col-sm-12">

                                        {this.state.isLoading ?
                                            <Loader />
                                            :
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th width="30%">Received</th>
                                                        <th width="70%" className="text-cell">File Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.osr.length === 0 &&
                                                        <tr>
                                                            <td colSpan="2" className="text-center">No records found.</td>
                                                        </tr>
                                                    }
                                                    {this.state.osr.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{moment(item.dateReceived).format("ddd DD MMM YY @ HH:mm")}</td>
                                                            <td className="text-cell">{item.fileName}</td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>
                                        }

                                    </div>
                                </div>

                            </FormContainer>

                        </NarrowContainer>
                    </div>
                </div>
            </div>
        )

    }

}

export default ClientLogsPage;