import React, { useState, useEffect } from "react";
import { SubmitButton } from "../../components/Button";
import { FormContainer } from "../../components/Container";
import { TextArea } from "../../components/TextInput";
import NotesAPI from "../../api/NotesAPI";
import moment from "moment";
import UserAPI from "../../api/UserAPI";

const Notes = ({ id }) => {
  const [notes, setNotes] = useState([]);
  const [authorId, setAuthorId] = useState("");
  const [noteBody, setNoteBody] = useState("");

  const loadNotes = () => {
    NotesAPI.getNotes(id, (data) => {
      setNotes(data);
      setNoteBody("");
    });
  };

  useEffect(() => {
    UserAPI.getUserData((data) => {
      if (data) {
        console.log(data);
        setAuthorId(data.userToken);
        loadNotes();
      }
    });
  }, [id]); // Reload when id changes

  const onChange = (name, value) => {
    setNoteBody(value);
  };

  const addNote = () => {
    if (noteBody !== "") {
      const payload = {
        linkedId: id,
        authorId: authorId,
        noteBody: noteBody,
      };
      NotesAPI.addNote(payload, () => {
        loadNotes();
      });
    }
  };

  return (
    <FormContainer
      title="Notes & History"
      description="Related notes and audit logs."
    >
      <div className="row">
        <div className="col-sm-12">
          <table>
            <thead>
              <tr>
                <th width="30%">Date/Author</th>
                <th width="70%" className="text-cell">
                  Note
                </th>
              </tr>
            </thead>
            <tbody>
              {notes.length === 0 && (
                <tr>
                  <td colSpan="2" className="text-center">
                    No notes have been added.
                  </td>
                </tr>
              )}
              {notes.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      lineHeight: 1.5,
                      paddingTop: 10,
                      paddingBottom: 10,
                      verticalAlign: "top",
                    }}
                  >
                    {item.authorName}
                    <br />
                    <small>
                      {moment(item.noteTime).format("ddd DD MMM YY @ HH:mm")}
                    </small>
                  </td>
                  <td
                    className="text-cell"
                    style={{
                      lineHeight: 1.5,
                      paddingTop: 10,
                      paddingBottom: 10,
                      verticalAlign: "top",
                    }}
                  >
                    {item.noteBody}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <TextArea
            name="noteBody"
            type="text"
            label="Add New Note"
            value={noteBody}
            rows={3}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <SubmitButton
            text="Add Note"
            className="btn btn-secondary"
            onClick={addNote}
          />
        </div>
      </div>
    </FormContainer>
  );
};

export default Notes;
