import apiRequest from '.';

class ProductAPI {

    getTree(parent, rule, setToken, isTradingCategory, cb) {
        apiRequest({ endpoint: `product/tree?parentID=${parent}&ruleID=${rule}&setToken=${setToken}&isTradingCategory=${isTradingCategory}` }, cb);
    }

    updTree(item, operation, rule, setToken, cb) {
        apiRequest({ endpoint: `product/updatetree?itemID=${item}&operation=${operation}&ruleID=${rule}&setToken=${setToken}`, method: 'POST' }, cb);
    }

    updTreeMultiple(items, operation, rule, setToken, cb) {
        apiRequest({ endpoint: `product/updatetreemultiple?items=${items}&operation=${operation}&ruleID=${rule}&setToken=${setToken}`, method: 'POST' }, cb);
    }

}

export default new ProductAPI();