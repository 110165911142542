import apiRequest from '.';

class RuleAPI {

    getRules(client, cb) {
        let url = `rule?client=${client}`;
        apiRequest({ endpoint: url }, cb);
    }

    getRule(id, cb) {
        apiRequest({ endpoint: `rule/details?id=${id}` }, cb);
    }

    getRuleClients(id, cb) {
        apiRequest({ endpoint: `rule/datasets?id=${id}` }, cb);
    }

    addRuleClient(rule, setToken, cb) {
        apiRequest({ endpoint: `rule/adddataset?rule=${rule}&setToken=${setToken}`, method: 'POST' }, cb);
    }

    delRuleClient(rule, setToken, cb) {
        apiRequest({ endpoint: `rule/deletedataset?rule=${rule}&setToken=${setToken}`, method: 'POST' }, cb);
    }

    updRule(payload, cb) {
        apiRequest({ endpoint: `rule/update`, method: 'POST', payload }, cb);
    }

}

export default new RuleAPI();