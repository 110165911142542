import apiRequest from '.';
import APIUtils from './APIUtils';
import axios from 'axios';

class AttachmentAPI {

    getAttachments(id, cb) {
        let url = `attachment?id=${id}`;
        apiRequest({ endpoint: url }, cb);
    }

    addAttachment(id, payload, cb) {

        let user = JSON.parse(localStorage.getItem('userData'));
        axios.post(APIUtils.getBaseUrl() + `attachment/add?id=${id}`, payload, {
            headers: {
              'accept': 'application/json',
              'Accept-Language': 'en-US,en;q=0.8',
              'Content-Type': `multipart/form-data; boundary=${payload._boundary}`,
              'auth_token': user.userToken
            }
          })
            .then((response) => {
                console.log("Response: ", response);
                cb(response);
            }).catch((error) => {
                console.log("Err: ", error);
                cb(error);
            });
          

    }

    delAttachment(id, cb) {
        apiRequest({ endpoint: `attachment/delete?id=${id}`, method: 'GET', id }, cb);
    }

}

export default new AttachmentAPI();