import React from "react";
import { Link } from "react-router-dom";

const MainLink = ({ url, icon, title, count = 0 }) => {
  return (
    <Link to={url}>
      {title}
      {count ? <span>{count}</span> : ""}
    </Link>
  );
};

export default MainLink;
