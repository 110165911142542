import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormContainer, NarrowContainer } from '../../components/Container';
import { Title } from '../../components/Title';
import { BackButton } from '../../components/Button';
import { ClientMenu } from '../../components/Menu';
import RuleAPI from '../../api/RuleAPI';
import { Loader } from '../../components/Loader';

function ClientRulesPage({ navigation }) {

    const { id } = useParams();

    const [rules, setRules] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadRules();
    }, []);


    const loadRules = () => {
        RuleAPI.getRules(id, (data) => {
            setRules(data.rules);
            setIsLoading(false);
        })
    }

    return (
        <div className="container-fluid">
            <div className="row row-eq-height">
                <div className="col-sm-12 content">
                    <NarrowContainer>

                        <BackButton url={'/clients'} title="Back" />
                        <Title title={"Price Rules"} />

                        <ClientMenu id={id} />

                        {/*<FormContainer title="Price Rule Settings"
                        description={`Applicable settings for all client price rules.`}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <SwitchButton
                                        label="Cost Plus Pricing"
                                        text="Apply Cost Plus Pricing to all Clients Price Rules"
                                        name="costPlusPricing"
                                        value={this.state.costPlusPricing}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>
                        </FormContainer>*/}

                        <FormContainer title="Price Rules"
                            description={`This clients applicable price rules.`}>
                            <div className="row">
                                <div className="col-sm-12">

                                    {isLoading ?
                                        <Loader />
                                        :
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Rule</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {rules.map((item, index) =>
                                                    <tr key={index}>
                                                        <td><Link to={`/rules/${item.ruleID}`}>{item.ruleName}</Link></td>
                                                        <td>{item.ruleDesc}</td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                    }

                                </div>
                            </div>
                        </FormContainer>

                    </NarrowContainer>
                </div>
            </div>
        </div>
    )

}

export default ClientRulesPage;