import React from 'react';
import { Link } from 'react-router-dom';

class Title extends React.Component {

    render() {
        return (
            <div>
                <h1 className={`luna-h1 ${this.props.subtitle ? 'has-subtitle' : ''}`}>{this.props.title}{this.props.button ? <span><Link className="ln-c-button ln-c-button--filled" to={this.props.link}>{this.props.button}</Link></span> : ''}</h1>
                {this.props.subtitle ? <h4>{this.props.subtitle}</h4> : ''}
            </div>
        )
    }

};

export default Title;