import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../../components/Container';
import { Title } from '../../components/Title';
import { TextInput } from '../../components/TextInput';
import { Loader } from '../../components/Loader';
import ClientAPI from '../../api/ClientAPI';

function ClientsPage({ navigation }) {

    const [keyword, setKeyword] = useState('');
    const [clients, setClients] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadClients();
    }, []);


    const loadClients = () => {
        setIsLoading(true);
        ClientAPI.getClients((data) => {
            setClients(data.dataSets);
            setIsLoading(false);
        })
    }

    return (
        <div className="container-fluid">
            <div className="row row-eq-height">
                <div className="col-sm-12 content">
                    <Title title="Clients" button="Add new" link="/clients/new" />
                    <Container>

                        <div className="row">
                            <div className="col-sm-12">
                                <TextInput
                                    name="keyword"
                                    label="Search clients"
                                    text="Search"
                                    value={keyword}
                                    onChange={(name, value) => setKeyword(value)}
                                />
                            </div>
                        </div>

                        {isLoading ?
                            <Loader />
                            :

                            <div>

                                <table>
                                    <thead>
                                        <tr>
                                            <th width="25%">Account Number</th>
                                            <th width="45%">Client Name</th>
                                            <th width="30%">Currency</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {clients.filter(d => keyword === '' || (d.setName.toLowerCase()).includes(keyword.toLowerCase())).map((item, index) =>
                                            <tr key={index}>
                                                <td><Link to={`/clients/${item.setToken}`}>{item.businessAccNo}</Link></td>
                                                <td><Link to={`/clients/${item.setToken}`}>{item.setName}</Link></td>
                                                <td>{item.currencyCode} - {item.currencyName}</td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>

                            </div>
                        }

                    </Container>

                </div>
            </div>
        </div>
    )

}

export default ClientsPage;