import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../../components/Container';
import { Title } from '../../components/Title';
import { TextInput } from '../../components/TextInput';
import { Loader } from '../../components/Loader';
import RuleAPI from '../../api/RuleAPI';

function RulesPage({ navigation }) {

    const [keyword, setKeyword] = useState('');
    const [rules, setRules] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const loadRules = () => {
        setIsLoading(true);
        RuleAPI.getRules("", (data) => {
            console.log(data);
            setRules(data.rules);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        loadRules();
    }, []);

    return (
        <div className="container-fluid">
            <div className="row row-eq-height">
                <div className="col-sm-12 content">
                    <Title title="Price Rules" button="Add new" link="/rules/new" />
                    <Container>

                        <div className="row">
                            <div className="col-sm-12">
                                <TextInput
                                    name="keyword"
                                    label="Search rules"
                                    text="Search"
                                    value={keyword}
                                    onChange={(name, value) => setKeyword(value)}
                                />
                            </div>
                        </div>

                        {isLoading ?
                            <Loader />
                            :
                            <div>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Rule</th>
                                            <th>Client</th>
                                            <th>Applicable Clients</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {rules.filter(d => keyword === '' || (d.ruleName.toLowerCase()).includes(keyword.toLowerCase())).map((item, index) =>
                                            <tr key={index}>
                                                <td><Link to={`/rules/${item.ruleID}`}>{item.ruleName}</Link></td>
                                                <td>{item.ruleDesc}</td>
                                                <td>{item.setName}</td>
                                                <td>{item.applicableClients}</td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>

                            </div>
                        }

                    </Container>

                </div>
            </div>
        </div>
    )

}

export default RulesPage;