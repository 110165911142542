import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Container } from '../../components/Container';
import { Title } from '../../components/Title';
import { TextInput } from '../../components/TextInput';
import { SubmitButton } from '../../components/Button';
import { Loader } from '../../components/Loader';
import ConsoleAPI from '../../api/ConsoleAPI';
import UserAPI from '../../api/UserAPI';

function UsersPage({ navigation }) {

    const [keyword, setKeyword] = useState('');
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = () => {
        let params = {

        }
        UserAPI.getUsers(params, (data) => {
            setUsers(data.users);
            setIsLoading(false);
        });
    }

    const exportData = () => {
        ConsoleAPI.exportUserPermissions((data) => {
            console.log(data);
            window.open(data.url) //to open new page
        })
    }

    return (
        <div className="container-fluid">
            <div className="row row-eq-height">
                <div className="col-sm-12 content">
                    <Title title="Users" button="Add new" link="/users/new" />
                    <Container>

                        <div className="row">
                            <div className="col-sm-12">
                                <TextInput
                                    name="keyword"
                                    label="Search users"
                                    text="Search"
                                    value={keyword}
                                    onChange={(name, value) => setKeyword(value)}
                                />
                            </div>
                        </div>

                        {isLoading ?
                            <Loader />
                            :
                            <div>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email Address</th>
                                            <th>Last Login</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {users.filter(d => keyword === '' || (d.firstName.toLowerCase() + ' ' + d.lastName.toLowerCase() + d.emailAddress.toLowerCase()).includes(keyword.toLowerCase())).map((item, index) =>
                                            <tr key={index}>
                                                <td><Link to={`/users/${item.userToken}`}>{item.firstName} {item.lastName}</Link></td>
                                                <td>{item.emailAddress}</td>
                                                <td>{moment(item.lastIn).format("DD MMM YYYY @ HH:mm")}</td>
                                                <td>{item.isSuspended ? <span className="red-warning">Suspended</span> : moment().diff(moment(item.lastIn), 'days') > 90 ? <span className="grey-warning">Inactive</span> : <span className="yellow-warning">Active</span>}</td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>

                            </div>
                        }

                        <div className="row">
                            <div className="col-sm-12" style={{ paddingBottom: 60 }}>
                                <SubmitButton
                                    text="Export Users"
                                    className="btn btn-secondary"
                                    onClick={exportData}
                                />
                            </div>
                        </div>

                    </Container>

                </div>
            </div>
        </div>
    )

}

export default UsersPage;