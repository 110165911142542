import React from 'react';

const Loader = () => (
    <div className="loader">
        <svg
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
            className="ln-c-progress-spinner ln-c-progress-spinner--large"
        >
            <circle
                cx="24"
                cy="24"
                r="18"
                fill="none"
                strokeWidth="5"
                stroke="currentColor"
                className="ln-c-progress-spinner__bg"
            ></circle>
            <circle
                cx="24"
                cy="24"
                r="18"
                fill="none"
                strokeWidth="5"
                stroke="currentColor"
                className="ln-c-progress-spinner__fill"
                strokeDasharray="28.27px 84.82px"
            ></circle>
        </svg>
    </div>
);

export default Loader;