import React from "react";

class DiscountInput extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.url && this.props.button) {
      console.warn(
        "Both a url and button prop were passed to the TextInput component"
      );
    }
  }

  onFieldChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (parseFloat(value) <= 10 || value === "") {
      this.props.onChange(name, value);
    }
  }

  validateInput(e) {
    const target = e.target;
    var _countDecimals = 0;
    if (target.value.toString().includes(".")) {
      _countDecimals = target.value.toString().split(".")[1].length || 0;
    }
    const re = RegExp("^[0-9]+(.[0-9]{2})?$");
    if ((!re.test(e.key) && e.key !== ".") || _countDecimals === 2) {
      e.preventDefault();
    }
  }

  render() {
    return (
      <div className="ln-c-form-group">
        {this.props.label ? (
          <label className="ln-c-label" htmlFor={this.props.name}>
            {this.props.label}
            {this.props.asterix ? <span className="asterix">*</span> : ""}
          </label>
        ) : (
          ""
        )}
        {this.props.tip ? <div className="tip">{this.props.tip}</div> : ""}
        <input
          id={this.props.name}
          type={"text"}
          name={`${this.props.name}`}
          placeholder={this.props.placeholder}
          onChange={this.onFieldChange.bind(this)}
          value={this.props.value}
          className="ln-c-text-input ln-c-input-group__control"
          onKeyPress={(e) => this.validateInput(e)}
          autoComplete={`auto-${this.props.name}`}
          disabled={this.props.disabled ? this.props.disabled : false}
          max={this.props.max ? this.props.max : 10}
        />
        <div className="input-meta">
          {this.props.notes ? (
            <div className="invalid-error input-meta--error">
              <small>
                <em>{this.props.notes}</em>
              </small>
            </div>
          ) : (
            ""
          )}
          {this.props.error ? (
            <div className="invalid-error input-meta--error">
              {this.props.error}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default DiscountInput;
